import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Typography
} from "@material-ui/core";
import {
  WithStyles,
  createStyles,
  withStyles
} from "@material-ui/core/styles";
import { Close } from "@material-ui/icons";
import React from "react";
import BulkUploading from "../../../blocks/bulkuploading/main/BulkUploadingCompanyInfo.web";

interface Props extends WithStyles<any> {
  open: boolean;
  history: any;
  onClose: () => void;
  triggerAutoFill: () => void;
}

const styles = () =>
  createStyles({
    content: {
      position: "relative",
      padding: "40px !important"
    },

    closeIcon: {
      position: "absolute",
      right: 32,
      top: 30,
      zIndex: 1
    },

    description: {
      fontSize: 24,
      fontWeight: 700,
      lineHeight: "34px",
      letterSpacing: "-0.005em",
      textAlign: "center"
    },

    subDescription: {
      marginTop: 12,
      fontSize: 16,
      fontWeight: 600,
      lineHeight: "19px",
      textAlign: "center",
      color: "#475569"
    },

    highlight: {
      fontSize: 24,
      fontWeight: 700,
      lineHeight: "34px",
      letterSpacing: "-0.005em",
      textAlign: "center",
      color: "#DC2626"
    },

    action: {
      marginTop: 32,
      display: "flex",
      alignItems: "center",
      gap: 8
    },

    deleteButton: {
      color: "#FFFFFF",
      border: "1px solid #DC2626",
      backgroundColor: "#DC2626",
      padding: "10px 16px",
      borderRadius: 8,
      cursor: "pointer",
      flex: 1,
      fontWeight: 600,
      fontSize: 16,
      lineHeight: "19.2px"
    },

    backButton: {
      backgroundColor: "#FFFFFF",
      border: "1px solid #CBD5E1",
      color: "#000000",
      padding: "10px 16px",
      borderRadius: 8,
      cursor: "pointer",
      flex: 1,
      fontWeight: 600,
      fontSize: 16,
      lineHeight: "19.2px"
    }
  });

export class UploadDocumentsDialog extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes, open, onClose } = this.props;

    return (
      <>
        <Dialog
          open={open}
          className={classes.dialog}
          PaperProps={{
            style: { borderRadius: 20, maxWidth: 848, width: "100%" }
          }}
        >
          <DialogContent className={classes.content}>
            <IconButton className={classes.closeIcon} onClick={onClose}>
              <Close />
            </IconButton>

            <BulkUploading
              id={""}
              history={this.props.history}
              view={'modal'}
              onClose={onClose}
              triggerAutoFill={this.props.triggerAutoFill}
            />
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

export default withStyles(styles)(UploadDocumentsDialog);
