import React, { useEffect, useState } from "react";
import { Backdrop, Box, Button, CircularProgress, InputAdornment, Link, Typography } from "@material-ui/core";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { useHistory } from "react-router-dom";
import TextField from "../../../../components/main/CustomFields/TextField";
import { saveNewPassword } from "../../../../redux/services/authentication";
import { clearStorages, getUrlAndParams, removeUrlParams } from "../../../../helpers/other";
import { enqueueSnackbar } from "notistack";

import { useStyles } from "./styles";

const ResetPassword = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [token, setToken] = useState<string>('');
  const [userId, setUserId] = useState<string>('');

  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    const { params } = getUrlAndParams();
    const token = params.get('token');

    const match = props.match.params;

    if (token?.length && 'id' in match) {
      setToken(token);
      setUserId(match.id);
    } else {
      history.push('/');
    }
  }, []);

  const handleSaveNewPassword = async (newPassword: string, confirmPassword: string) => {
    if (token?.length && userId?.length) {
      setLoading(true);
  
      const body = {
        userId: userId,
        password: confirmPassword,
      }
  
      const { error } = await saveNewPassword({ token, body });
  
      if (!error) {
        history.push('/password-recovery-success');
      } else {
        enqueueSnackbar(
          error?.data?.message || 'Something went wrong',
          { variant: 'error' }
        )
      }
    } else {
      enqueueSnackbar(
        'Invalid link data',
        { variant: 'error' }
      )
    }

    setLoading(false);
  }

  return (
    <>
      <Backdrop
        style={{ color: '#fff', zIndex: 9999 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box className={classes.containerStyle}>
        <Box className={classes.signupform}>
          <Typography
            align="center"
            className={classes.typographyStyle1}
            variant="h6"
          >
            New Password
          </Typography>
          <Box>
            <Formik
              initialValues={{
                newPassword: "",
                confirmPassword: "",
              }}
              validationSchema={Yup.object({
                newPassword: Yup.string()
                  .required("New Password is required.")
                  .min(6, "Password must be at least 6 characters.")
                  .matches(
                    /^(?=.*[0-9])(?=.*[!@#$%^&*()_+\-?'`])(?!.*\s).{8,}$/,
                    "Password is too weak. Include at least 1 number and 1 special character."
                  ),
                confirmPassword: Yup.string()
                  .required("Confirm Password is required.")
                  .oneOf([Yup.ref("newPassword"), ""], "Both Passwords must match.")
              })}
              onSubmit={(values) => handleSaveNewPassword(values.newPassword, values.confirmPassword)}
            >
              {({
                errors,
                values,
                touched,
                setFieldValue
              }) => (
                <Form
                  translate={undefined}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px',
                    marginTop: '29px'
                  }}
                >
                  <TextField
                    name={'newPassword'}
                    value={values['newPassword']}
                    label={'New Password'}
                    placeholder={'Your new password'}
                    type={'password'}
                    error={touched['newPassword'] && errors['newPassword']}
                    onChange={(e) => setFieldValue('newPassword', e.target.value)}
                  />
                  <TextField
                    name={'confirmPassword'}
                    value={values['confirmPassword']}
                    label={'Confirm New Password'}
                    placeholder={'Re-enter your new password'}
                    type={'password'}
                    error={touched['confirmPassword'] && errors['confirmPassword']}
                    onChange={(e) => setFieldValue('confirmPassword', e.target.value)}
                  />
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    type="submit"
                    style={{
                      marginTop: '16px'
                    }}
                  >
                    Save
                  </Button>
                </Form>
              )}
            </Formik>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ResetPassword;
