import { api, apiCall } from "../api";
import { ApiResponse } from "../types";

const profileAPI = api.injectEndpoints({
  endpoints: (builder) => ({
    getAccountUsers: builder.query<any, any>({
      query: (params) => ({
        url: "/v1/account/users",
        method: "GET",
        params
      }),
      providesTags: ["UserManagement"],
    }),
    inviteAccountUser: builder.mutation<any, any>({
      query: (body) => ({
        url: "/v1/account/users",
        method: "POST",
        body: {
          user: body
        }
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "UserManagement", data: arg }
      ]
    }),
    deleteAccountUser: builder.mutation<any, any>({
      query: (id) => ({
        url: `/v1/account/users/${id}`,
        method: "DELETE"
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "UserManagement", data: arg }
      ]
    }),
    updateAccountUser: builder.mutation<any, any>({
      query: ({ id, body }) => ({
        url: `/v1/account/users/${id}`,
        method: "PUT",
        body
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "UserManagement", data: arg }
      ]
    })
  }),
  overrideExisting: true
});

export const {
  useGetAccountUsersQuery,
  useInviteAccountUserMutation,
  useUpdateAccountUserMutation
} = profileAPI;

// Create a function to call the API without using the hook
export const getAccountUsers = async (params): Promise<any> => {
  return apiCall(profileAPI.endpoints.getAccountUsers, params);
};

export const inviteAccountUser = async (body): Promise<any> => {
  return apiCall(profileAPI.endpoints.inviteAccountUser, body);
};

export const deleteAccountUser = async (id): Promise<any> => {
  return apiCall(profileAPI.endpoints.deleteAccountUser, id);
};

export const updateAccountUser = async ({ id, body }): Promise<any> => {
  return apiCall(profileAPI.endpoints.updateAccountUser, { id, body });
};
