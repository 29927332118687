import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    // maxWidth: '1920px',
    height: '100vh',
    background: 'linear-gradient(130.05deg, #F9F9F9 -25.99%, #EBE2CF 77.55%)',
    overflowX: 'hidden',
    overflowY: 'auto',
    position: 'relative'
  },
  content: {
    padding: '28px 190px 60px 180px',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '10px',
    [theme.breakpoints.down(1360)]: {
      padding: '28px 90px 60px 80px',
    },
  },
  footer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '12px',
  },
  footerButtons: {
    display: 'flex',
    columnGap: '8px',
  },
}));