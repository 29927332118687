import React from "react";
import CustomList from "../../../components/main/CustomList.web";
import CustomInput from "../../../components/main/CustomInput.web";

import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Box,
  Typography,
  InputAdornment,
  FormHelperText
} from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';

import ModularProductsController, {
  Props,
  configJSON,
} from "./ModularProductsController";
import { styles } from "../utils/styles";

export class ModularProducts extends ModularProductsController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { title, inputList, serviceArray, showListHeader, processText, classes,
      formikError, formikValue, name, formikHandelChange, tootipData
    } = this.props
    const containerStyle = { ...webStyle.mainContainer, ...(this.styleCondition(processText, webStyle.servicesMainContainer, {})) };
    return (

      <>
        <Grid container style={this.styleCondition(!!showListHeader, {}, containerStyle)} >
          <Grid item xs={12} style={{ ...webStyle.container, ...(this.styleCondition(processText, {}, webStyle.servicesContainer)) }}>
            {!showListHeader && <CustomList
              title={title}
              modalContent={tootipData || ""}
              servicesList={serviceArray}
            />}
            <Box style={{ ...(this.styleCondition(processText, webStyle.processStyle, {})), ...webStyle.offeringBox }}>
              {formikValue.map((data: any, index: any) => {
                return (
                  <Box style={this.styleCondition(processText, { width: '100%' }, { width: "32%" })} key={index}>
                    <CustomInput
                      placeholder={inputList[index].namePlaceholder}
                      data-testid={"name-field-" + index}
                      textLabel={this.styleCondition(processText, "", inputList[index].nameTitle)}
                      customeLabel={this.styleCondition(!processText, "", inputList[index].nameTitle.replace("N", index + 1))}
                      labelCount={index}
                      rowsCount={1}
                      width="100%"
                      maxCharacterLenght={inputList[index].charLength}
                      name={`${name}.${index}.name`}
                      onChange={(
                        e: React.ChangeEvent<HTMLTextAreaElement>
                      ) => formikHandelChange(e, index)}
                      value={formikValue?.[index]?.name}
                      error={formikError?.[index]?.name}
                    />
                    <>
                      <Typography style={webStyle.offering}>{inputList[index].priceTitle}</Typography>
                      <div>
                        <TextField
                          variant="outlined"
                          minRows={1}
                          multiline
                          placeholder={inputList[index].pricePlaceholder}
                          maxRows={12}
                          name={`${name}.${index}.price`}
                          onChange={(
                            e: React.ChangeEvent<HTMLTextAreaElement>
                          ) => formikHandelChange(e, index)}
                          value={formikValue?.[index]?.price}
                          InputProps={{
                            endAdornment: (
                              <div style={{ width: '100%' }} className={classes.priceCurrencyInput}>
                                <InputAdornment position="end" >
                                  <FormControl variant="outlined" style={{ ...webStyle.inputProps1, width: "100%", }}>
                                    <Select
                                      id="currency"
                                      labelId="currency-label"
                                      data-testid={"currency-field-" + index}
                                      onChange={(e: any) => formikHandelChange(e, index)}
                                      label="Currency"
                                      style={{
                                        ...webStyle.inputProps1,
                                        width: "100%",
                                        fontStyle: this.styleCondition(data.price, "normal", "normal"),
                                        color: this.styleCondition(data.price, '#000', "#94A3B8")
                                      }}

                                      name={`${name}.${index}.currency`}
                                      value={formikValue?.[index].currency}

                                    >
                                      <MenuItem value="null"> Choose </MenuItem>
                                      <MenuItem value="USD" > USD </MenuItem>
                                      <MenuItem value="EUR" > EUR </MenuItem>
                                      <MenuItem value="GBP" > GBP </MenuItem>
                                    </Select>
                                  </FormControl>
                                </InputAdornment>
                              </div>
                            ),
                            style: {
                              ...webStyle.inputProps, width: "100%",
                              fontStyle: this.styleCondition(data.price, "normal", "normal"),
                              color: this.styleCondition(data.price, '#000', "#94A3B8")
                            }
                          }}
                          style={{ ...webStyle.inputProps1, width: "100%", padding: '10px 0px 10px 10px !important' }}
                          type="number"
                          data-testid={"price-field-" + index}
                          error={formikError?.[index]?.price}
                        />
                        {formikError?.[index]?.price && (
                          <FormHelperText error>
                            {formikError?.[index]?.price}
                          </FormHelperText>
                        )}
                      </div>
                    </>
                    <Box style={{ margin: "15px 0px" }}>
                      <CustomInput
                        textLabel={inputList[index].descriptionTitle}
                        labelCount={index}
                        placeholder={inputList[index].descriptionPlaceholder}
                        rowsCount={2}
                        data-testid={"description-field-" + index}
                        maxCharacterLenght={inputList[index].charLengthDesc}
                        width="100%"
                        name={`${name}.${index}.description`}
                        onChange={(
                          e: React.ChangeEvent<HTMLTextAreaElement>
                        ) => formikHandelChange(e, index)}
                        value={formikValue?.[index]?.description}
                        error={formikError?.[index]?.description}
                      />
                    </Box>
                  </Box>
                )
              }
              )}
            </Box>
          </Grid>
        </Grid>
      </>
    );
  }
}

const webStyle = {
  mainContainer: {
    // background: '#EEE7D9',
  },
  servicesMainContainer: {
    margin: '0.8rem 0px',
  },
  container: {
    borderRadius: '28px',
    background: '#fff',
    height: 'max-content'
  },
  servicesContainer: {
    padding: '32px 40px',
  },
  offeringBox: {
    marginBottom: '2%',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap' as 'wrap',
  },
  processStyle: {
    flexDirection: 'column' as 'column'
  },
  infoBox: {
    gap: '10px',
    display: 'flex',
    alignItems: 'center'
  },
  servicesHead: {
    fontWeight: 600
  },
  inputProps: {
    borderRadius: '8px',
    fontFamily: 'Montserrat',
    fontWeight: 400,
    lineHeight: '21px',
    padding: '10px 0px 10px 10px',
  },
  inputProps1: {
    // padding: '10px 0px 10px 10px',
    // padding: '10px',
  },
  offering: {
    fontSize: '0.87rem',
    fontWeight: 500,
    paddingLeft: "12px"
  },
}

export default withStyles(styles)(ModularProducts);
