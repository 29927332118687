export const STEPS_TYPE = {
  SIGN_UP: "sign-up-step",
  ONBOARDING: "onboarding-step"
};

export const SIGN_UP_STEPS = {
  VERIFY_EMAIL: "VERIFY_EMAIL",
  ADD_BASIC_INFO: "ADD_BASIC_INFO",
  ADD_DETAIL_INFO: "ADD_DETAIL_INFO",
  // ADD_COMPANY_INFO: 'ADD_COMPANY_INFO',
  DESCRIBE_BUSINESS_1: "DESCRIBE_BUSINESS_1"
  // DESCRIBE_BUSINESS_2: 'DESCRIBE_BUSINESS_2',
};

export const ONBOARDING_STEPS = {
  CHOOSE_PATH: "CHOOSE_PATH",
  ONBOARDING_STARTED: "ONBOARDING_STARTED",
  // ABOUT_COMPANY: 'ABOUT_COMPANY',
  // YOUR_APPROACH: 'YOUR_APPROACH',
  // OFFERINGS: 'OFFERINGS',
  CHOOSE_PLAN: "CHOOSE_PLAN"
  // DESCRIBE_BUSINESS_PLAN_1: 'DESCRIBE_BUSINESS_PLAN_1',
  // DESCRIBE_BUSINESS_PLAN_2: 'DESCRIBE_BUSINESS_PLAN_2',
};

export const GET_URL_BY_STEP = {
  [SIGN_UP_STEPS.VERIFY_EMAIL]: "/VerifySignUpCode",
  [SIGN_UP_STEPS.ADD_BASIC_INFO]: "/account-details",
  [SIGN_UP_STEPS.ADD_DETAIL_INFO]: "/about",
  // [SIGN_UP_STEPS.ADD_COMPANY_INFO]: '/letsgetstarted',
  [SIGN_UP_STEPS.DESCRIBE_BUSINESS_1]: "/company-describe",
  // [SIGN_UP_STEPS.DESCRIBE_BUSINESS_2]: '/company-about',
  [ONBOARDING_STEPS.CHOOSE_PATH]: "/chooseyourpath",
  [ONBOARDING_STEPS.ONBOARDING_STARTED]: "/BulkUploading",
  // [ONBOARDING_STEPS.ABOUT_COMPANY]: '/onboarding/about-the-company',
  // [ONBOARDING_STEPS.YOUR_APPROACH]: '/onboarding/about-your-approach',
  // [ONBOARDING_STEPS.OFFERINGS]: '/onboarding/offering-the-company',
  [ONBOARDING_STEPS.CHOOSE_PLAN]: "/plans"
  // [ONBOARDING_STEPS.DESCRIBE_BUSINESS_PLAN_1]: '/describle-business-plan',
  // [ONBOARDING_STEPS.DESCRIBE_BUSINESS_PLAN_2]: '/tell-more-business',
};

export const PERMISSIONS = {
  REVIEW_INTEL: "Review",
  GENERATE_INTEL: "Generate",
  CHANGE_ONBOARDING: "Edit"
};

export const LAYOUTS = {
  CAROUSEL_LAYOUT: "CarouselLayout",
  ONBOARDING_LAYOUT: "OnboardingLayout",
  PLATFORM_LAYOUT: "PlatformLayout"
};
