import { apiCall } from "./../api";
import { api } from "../api";
import { ApiResponse } from "../types";

const tacticalOutreachAPI = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllCampaigns: builder.query<any, any>({
      query: (id) => ({
        url: `/v1/tactical_outreach/campaign_types?categoryId=${id}`,
        method: "GET"
      }),
    }),
    getCampaignTemplate: builder.query<any, any>({
      query: (id) => ({
        url: `/v1/tactical_outreach/campaign_templates?typeId=${id}`,
        method: "GET"
      }),
    }),
    saveCampaignTemplate: builder.mutation<any, any>({
      query: (body) => ({
        url: `/v1/tactical_outreach/campaigns`,
        method: "POST",
        body
      })
    }),
    saveCampaignParameters: builder.mutation<any, any>({
      query: (body) => ({
        url: `/v1/tactical_outreach/answers`,
        method: "PUT",
        body
      })
    }),
    getCampaignParameters: builder.mutation<any, any>({
      query: (id) => ({
        url: `/v1/tactical_outreach/answers/${id}`,
        method: "GET",
      })
    }),
    triggerEmailGenerate: builder.mutation<any, any>({
      query: ({ type, campaignId }) => ({
        url: `/v1/tactical_outreach/emails/generate/${type}/${campaignId}`,
        method: "POST"
      })
    }),
    getCampaignEmails: builder.query<any, any>({
      query: (id) => ({
        url: `/v1/tactical_outreach/emails?campaignId=${id}`,
        method: "GET"
      }),
    }),
    updateCampaignEmail: builder.mutation<any, any>({
      query: ({ body, id }) => ({
        url: `/v1/tactical_outreach/emails/${id}`,
        method: "PUT",
        body
      })
    }),
    // saveCampaignEmail: builder.mutation<any, any>({
    //   query: (body) => ({
    //     url: `/v1/tactical_outreach/emails`,
    //     method: "POST",
    //     body
    //   })
    // }),
    getCampaignById: builder.query<any, any>({
      query: (id) => ({
        url: `/v1/tactical_outreach/campaigns/${id}`,
        method: "GET"
      }),
    }),
    updateCampaign: builder.mutation<any, any>({
      query: ({ body, id }) => ({
        url: `/v1/tactical_outreach/campaigns/${id}`,
        method: "PUT",
        body
      })
    }),
    regenerateEmail: builder.mutation<any, any>({
      query: ({ type, emailId }) => ({
        url: `/v1/tactical_outreach/emails/regenerate/${type}/${emailId}`,
        method: "PUT",
      })
    }),
    getCampaignEmailParameters: builder.mutation<any, any>({
      query: (id) => ({
        url: `/v1/tactical_outreach/email_parameters/${id}`,
        method: "GET",
      })
    }),
    saveCampaignEmailParameters: builder.mutation<any, any>({
      query: (body) => ({
        url: `/v1/tactical_outreach/email_parameters`,
        method: "PUT",
        body
      })
    }),
  }),
  overrideExisting: true
});

// Create a function to call the API without using the hook
export const getAllCampaigns = async (id): Promise<any> => {
  return apiCall(tacticalOutreachAPI.endpoints.getAllCampaigns, id);
};

export const getCampaignTemplate = async (id): Promise<any> => {
  return apiCall(tacticalOutreachAPI.endpoints.getCampaignTemplate, id);
};

export const saveCampaignTemplate = async (body): Promise<any> => {
  return apiCall(tacticalOutreachAPI.endpoints.saveCampaignTemplate, body);
};

export const saveCampaignParameters = async (body): Promise<any> => {
  return apiCall(tacticalOutreachAPI.endpoints.saveCampaignParameters, body);
};

export const getCampaignParameters = async (id): Promise<any> => {
  return apiCall(tacticalOutreachAPI.endpoints.getCampaignParameters, id);
};

export const triggerEmailGenerate = async ({ type, campaignId }): Promise<any> => {
  return apiCall(tacticalOutreachAPI.endpoints.triggerEmailGenerate, { type, campaignId });
};

export const getCampaignEmails = async (id): Promise<any> => {
  return apiCall(tacticalOutreachAPI.endpoints.getCampaignEmails, id);
};

export const updateCampaignEmail = async ({ body, id }): Promise<any> => {
  return apiCall(tacticalOutreachAPI.endpoints.updateCampaignEmail, {
    body,
    id
  });
};

// export const saveCampaignEmail = async (body): Promise<any> => {
//   return apiCall(tacticalOutreachAPI.endpoints.saveCampaignEmail, body);
// };

export const getCampaignById = async (id): Promise<any> => {
  return apiCall(tacticalOutreachAPI.endpoints.getCampaignById, id);
};

export const updateCampaign = async ({ body, id }): Promise<any> => {
  return apiCall(tacticalOutreachAPI.endpoints.updateCampaign, { body, id });
};

export const regenerateEmail = async ({ type, emailId }): Promise<any> => {
  return apiCall(tacticalOutreachAPI.endpoints.regenerateEmail, { type, emailId });
};

export const getCampaignEmailParameters = async (id): Promise<any> => {
  return apiCall(tacticalOutreachAPI.endpoints.getCampaignEmailParameters, id);
};

export const saveCampaignEmailParameters = async (body): Promise<any> => {
  return apiCall(tacticalOutreachAPI.endpoints.saveCampaignEmailParameters, body);
};