import React from "react";
import { useHistory } from "react-router-dom";
import moment from 'moment-timezone';
import { Box, Button, CircularProgress, IconButton, Typography } from "@material-ui/core";
import { trimTextByCharacters } from "../../../../helpers/text";
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg';
import { ReactComponent as CalendarIcon } from '../../../../assets/icons/calendar_icon.svg';
import { ReactComponent as ClockIcon } from '../../../../assets/icons/clock_icon.svg';
import { triggerEmailGenerate } from "../../../../redux/services/tactical-outreach";
import { enqueueSnackbar } from "notistack";
import { convertHTMLToText, convertMarkdownToHtml, convertMarkdownToText, isHTML, isMarkdown } from "../../helpers";
import { observableService } from "../../../../services/observable";
import { ReactComponent as ReloadIcon } from '../../../../assets/icons/reload_icon.svg';
import { ReactComponent as EditIcon } from '../../../../assets/icons/edit_dark.svg';

import { useStyles } from "./styles";

const SideBar = ({
  open,
  email,
  campaign,
  isSaveDisabled,
  setSideBarOpen,
  setEmail,
  setUnsavedDataModal,
  handleGetCampaign,
  setLoading,
  setShowStopGeneration,
}) => {
  const history = useHistory();
  const classes = useStyles();

  const userTimezone = moment.tz.guess();

  const handleTriggerEmailGenerate = async () => {
    if (campaign.status === 'INCOMPLETED') {
      setShowStopGeneration(true);
    } else {
      setLoading(true);

      const { data: email, error: emailError } = await triggerEmailGenerate({ 
        campaignId: campaign.id, 
        type: campaign.subCategory === 'MULTI_TOUCH_CAMPAIGNS' 
          ? 'multi_touch' 
          : 'single_touch' 
      });

      if (email && !emailError) {
        observableService.sendEvent('Increase usage amount');

        handleGetCampaign(campaign.id, null);

        enqueueSnackbar(
          'Email created successfully',
          { variant: 'success' }
        )
      } else {
        if (emailError?.status === 402) {
          observableService.sendEvent('Show top up modal for TACTICAL_OUTREACH');
        } else {
          enqueueSnackbar(
            'Failed to request AI',
            { variant: 'error' }
          )
        }

        observableService.sendEvent('Decrease usage amount');
      }

      setLoading(false);
    }
  }

  return (
    <>
      <Box className={`${classes.sideBar} ${open ? classes.sideBarOpen : ''}`}>
        <Box className={classes.sideBarCloseButton}>
          <IconButton onClick={() => setSideBarOpen(false)}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Box className={classes.sideBarContent}>
          {campaign.emails.map((item, index) => (
            <Box
              key={index}
              className={`${classes.sideBarItem} ${email.id === item.id ? classes.sideBarItemActive : ''}`}
              onClick={() => {
                if (!isSaveDisabled()) {
                  setUnsavedDataModal({ active: true, targetEmail: item })
                } else {
                  if (item.status !== 'READY') {
                    // setShowStopGeneration(true);
                  } else {
                    setEmail(item);
                  }
                }
              }}
              style={{
                position: 'relative',
                opacity: item.status === 'PENDING' ? '0.5' : '1',
                pointerEvents: item.status === 'PENDING' ? 'none' : 'all',
                boxShadow: item.status === 'ERROR' ? '-2px 0 0 0 #DC2626' : 'none',
                cursor: item.status === 'READY' ? 'pointer' : 'default'
              }}
            >
              {item.status === 'PENDING' && (
                <CircularProgress
                  color="inherit"
                  style={{
                    width: '30px',
                    height: '30px',
                    position: 'absolute',
                    top: '30%',
                    left: '42%',
                  }}
                />
              )}
              {item.status === 'ERROR' && (
                <Button
                  color="secondary"
                  variant="contained"
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    outline: '100px solid rgba(0, 0, 0, 0.2)'
                  }}
                  startIcon={<ReloadIcon style={{ color: 'black' }} />}
                  onClick={() => {
                    setLoading(true);
                    handleGetCampaign(campaign.id, email.id);
                  }}
                >
                  Refetch
                </Button>
              )}
              <Box style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <Typography noWrap style={{ fontSize: '14px', fontWeight: '600', lineHeight: '17px', color: '#334155' }}>
                  {item.subject?.trim()?.length ? item.subject : '—'}
                </Typography>
                {campaign.subCategory === 'MULTI_TOUCH_CAMPAIGNS' && campaign.status === 'COMPLETED' && item.status === 'READY' && (
                  <IconButton
                    size="small"
                    onClick={() => {
                      history.push(`/tactical-outreach/builder?campaign_id=${campaign.id}&target_email_id=${email.id}`)
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                )}
              </Box>
              <Typography style={{ fontSize: '12px', fontWeight: '500', lineHeight: '14px', color: '#475569' }}>
                {trimTextByCharacters(
                  isHTML(item.content)
                    ? convertHTMLToText(item.content)
                    : convertMarkdownToText(item.content),
                    50
                  )
                }
              </Typography>
              <Box style={{ display: 'flex', alignItems: 'center', gap: '6px', }}>
                <Typography style={{ display: 'flex', alignItems: 'center', gap: '4px', marginRight: '4px', fontSize: '10px', fontWeight: '500', color: '#64748B' }}>
                  <CalendarIcon />
                  {item.updatedAt?.trim()?.length ? moment.utc(item.updatedAt).tz(userTimezone).format('DD-MMM-YYYY') : '—'}
                </Typography>
                <Typography style={{ display: 'flex', alignItems: 'center', gap: '4px', fontSize: '10px', fontWeight: '500', color: '#64748B' }}>
                  <ClockIcon />
                  {item.updatedAt?.trim()?.length ? moment.utc(item.updatedAt).tz(userTimezone).format('HH:mm') : '—'}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>

        {campaign.subCategory !== 'MULTI_TOUCH_CAMPAIGNS' && (
          <Typography
            style={{ padding: '12px 20px', marginTop: '20px', fontSize: '16px', fontWeight: '600', lineHeight: '19px', color: '#22507B', cursor: 'pointer' }}
            onClick={() => handleTriggerEmailGenerate()}
          >
            + New email
          </Typography>
        )}
      </Box>
    </>
  );
};

export default SideBar;
