import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import clsx from 'clsx';
import { Avatar, Backdrop, Box, Button, CircularProgress, Divider, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, Toolbar, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import RightMenu from '../../blocks/multipageforms/main/RightMenu.web';
import ProfileModal from '../../blocks/customisableuserprofiles/main/ProfileModal.web';
import { ReactComponent as NavigationMenuOpenIcon } from '../../assets/icons/menu_open_icon.svg';
import { ReactComponent as NavigationMenuCloseIcon } from '../../assets/icons/menu_close_icon.svg';
import { ReactComponent as PebblesLogoIcon } from '../../assets/icons/pebbles_logo.svg';
import { ReactComponent as PebblesTextIcon } from '../../assets/icons/pebbles_text.svg';
import { ReactComponent as DashboardIcon } from '../../assets/icons/dashboard.svg';
import { ReactComponent as ArrowUpIcon } from '../../assets/icons/arrow_up.svg';
import { ReactComponent as ArrowDownIcon } from '../../assets/icons/arrow_down.svg';
import { ReactComponent as CopilotIcon } from '../../assets/icons/copilot.svg';
import { ReactComponent as TacticalOutreachIcon } from '../../assets/icons/tactical_outreach.svg';
import { ReactComponent as MyLibraryIcon } from '../../assets/icons/my_library.svg';
import { ReactComponent as MyWorkflowsIcon } from '../../assets/icons/my_workflows.svg';
import { ReactComponent as MyHistoryIcon } from '../../assets/icons/my_history.svg';
import { ReactComponent as ExploreUpgradesIcon } from '../../assets/icons/explore_upgrades.svg';
import { ReactComponent as CompanyIntelIcon } from '../../assets/icons/company_intel.svg';
import { ReactComponent as SettingsIcon } from '../../assets/icons/settings.svg';
import { ReactComponent as ProfileSettingsIcon } from '../../assets/icons/profile_settings.svg';
import { ReactComponent as UserManagementIcon } from '../../assets/icons/user_management.svg';
import { ReactComponent as UploadIcon } from '../../assets/icons/upload.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg';
import { ReactComponent as ShareAndEarnIcon } from '../../assets/icons/share_and_earn.svg';
import { ReactComponent as RightBarIcon } from '../../assets/icons/right_bar_icon.svg';
import SettingsModal from '../../components/main/SettingsModal';
import { useSelector } from 'react-redux';
import { getCurrentSubscription } from '../../redux/services/subscriptions';
import StorageProvider from '../../framework/main/StorageProvider';
import { getProfile } from '../../redux/services/profile';
import { getCurrentSubscriptionStatus } from '../../helpers/subscription';

interface Props {
  navigation: any;
}

const PlatformLayout = (props) => {
  const [menuItems, setMenuItems] = useState<any[]>([]);
  const [rightMenu, setRightMenu] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(true);
  const [profileModalVisible, setProfileModalVisible] = useState<boolean>(false);

  const history = useHistory();
  const classes = useStyles();

  const profile = useSelector((state: any) => state.profile.metadata);

  useEffect(() => {
    setMenuItems([
      {
        name: "Pebbles Copilot",
        icon: <CopilotIcon />,
        redirect: '/copilot',
        alternativeRoutes: [
          '/chat',
        ]
      },
      {
        name: "Tactical Outreach",
        icon: <TacticalOutreachIcon />,
        redirect: '/tactical-outreach',
        alternativeRoutes: []
      },
      // {
      //   name: "My Library",
      //   icon: <MyLibraryIcon />,
      //   redirect: '/my-library',
      //   drawer: true,
      //   alternativeRoutes: []
      // },
      // {
      //   name: "My Workflows",
      //   icon: <MyWorkflowsIcon />,
      //   redirect: '/my-workflow',
      //   alternativeRoutes: []
      // },
      {
        name: "My History",
        icon: <MyHistoryIcon />,
        drawer: true,
        redirect: '/my-history',
        alternativeRoutes: []
      },
      // {
      //   name: "Explore Upgrades",
      //   icon: <ExploreUpgradesIcon />,
      //   // redirect: '/subscription-explore-upgrade',
      //   redirect: '/subscriptions',
      //   alternativeRoutes: []
      // },
      {
        name: "Company Intel",
        icon: <CompanyIntelIcon />,
        redirect: '/company-intel',
        alternativeRoutes: []
      },
      {
        name: "Settings",
        icon: <SettingsIcon />,
        drawer: true,
        expanded: false,
        redirect: () => setProfileModalVisible(true),
        alternativeRoutes: []
        // redirect: () => profile.account.account_type === 'professional'
        //   ? handleProfileModal()
        //   : handleMenuExpand("Settings"),
        // subItems: [
        //   {
        //     name: "Profile Settings",
        //     icon: <ProfileSettingsIcon />,
        //     redirect: () => handleProfileModal(),
        //   },
        //   {
        //     name: "User Management",
        //     icon: <UserManagementIcon />,
        //     redirect: '/user-management',
        //   }
        // ]
      },
      // {
      //   name: "User Management",
      //   icon: <UserManagementIcon />,
      //   redirect: '/user-management',
      //   alternativeRoutes: [
      //     '/user'
      //   ]
      // },
    ])
  }, []);

  const handleDrawer = () => {
    setOpen(!open);
  };

  const handleMenuRedirect = async (url: string) => {
    await StorageProvider.setItem("isEdit", 'true');

    props.history.push(url);
  };

  // const handleMenuExpand = useCallback((title: string) => {
  //   if (profile) {
  //     setMenuItems((menuItems) =>
  //       menuItems.map((item) =>
  //         title === item.name
  //           ? { ...item, expanded: !item.expanded }
  //           : item
  //       )
  //     );
  //   }
  // }, [profile, setMenuItems]);

  const handleShareAndEarnPage = async () => {
    const { data } = await getCurrentSubscription();

    if (getCurrentSubscriptionStatus(data) !== 'ABSENT' && getCurrentSubscriptionStatus(data) !== 'EXPIRED') {
      history.push('/share-and-earn');
    } else {
      history.push('/subscriptions');
    }
  }

  return (
    <>
      {!menuItems.length
        ? <Backdrop
            style={{ color: '#fff', zIndex: 99999 }}
            open={true}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        : <Box className={classes.root}>
            <Box
              position="fixed"
              className={clsx(classes.appBar, {
                [classes.appBarShift]: open,
              })}
            >
              <Box>
                <Toolbar>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    data-testid="open-drawer"
                    onClick={handleDrawer}
                    edge="start"
                    className={clsx(classes.menuButton, {
                      [classes.hide]: open,
                    })}
                  >
                    <NavigationMenuCloseIcon />
                  </IconButton>
                </Toolbar>
              </Box>
            </Box>
            <Drawer
              variant="permanent"
              className={clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              })}
              classes={{
                paper: clsx({
                  [classes.drawerOpen]: open,
                  [classes.drawerClose]: !open,
                }),
              }}
            >
              <List
                className={classes.listContainer}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  height: '70px',
                  paddingTop: '20px',
                  paddingLeft: '20px',
                }}
              >
                <ListItem
                  className={classes.logoBox}
                  onClick={() => props.history.push('/home')}
                  style={{
                    height: '100%',
                  }}
                >
                  <ListItemIcon style={{ minWidth: '29px', margin: '0px 0px 0px 1px' }}>
                    <PebblesLogoIcon />
                  </ListItemIcon>
                  <ListItemText
                    // className={clsx({
                    //   [classes.hide]: !open
                    // })}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      margin: 0,
                      transform: 'translate(10px, 5px)',
                      visibility: open ? 'visible' : 'hidden'
                    }}
                  >
                    <PebblesTextIcon />
                  </ListItemText>
                </ListItem>
                <IconButton 
                  onClick={handleDrawer}
                  style={{
                    transform: 'translate(0px, 2px)'
                  }}
                >
                  <NavigationMenuOpenIcon />
                </IconButton>
              </List>
              <List className={classes.listContainer}>
                <Box
                  className={clsx(classes.dashboardBox, {
                    [classes.dashboardBoxOpen]: open,
                    [classes.dashboardBoxClose]: !open,
                  })}
                  data-testid="dashboard-button"
                  onClick={() => handleMenuRedirect('/home')}
                >
                  <ListItemIcon className={classes.listIcon}>
                    <DashboardIcon />
                  </ListItemIcon>
                  <Typography
                    variant="body1"
                    className={clsx({
                      [classes.hide]: !open
                    })}>
                    Dashboard
                  </Typography>
                </Box>
              </List>
              <List className={classes.listContainer}>
                {menuItems.map((list, index) => {
                  return (
                    <Box key={index + list.name}>
                      <ListItem
                        button
                        onClick={() => {
                          if (typeof list.redirect === 'function') {
                            list.redirect();
                          } else if (typeof list.redirect === 'string') {
                            handleMenuRedirect(list.redirect);
                          }
                        }}
                        selected={
                          props.location.pathname.includes(list.redirect) || 
                          list.alternativeRoutes?.some(item => props.location.pathname.includes(item))
                        }
                        className={
                          props.location.pathname.includes(list.redirect) || 
                          list.alternativeRoutes?.some(item => props.location.pathname.includes(item))
                            ? classes.activeLink 
                            : classes.listText
                          }
                      >
                        <ListItemIcon className={classes.listIcon}>
                          {list.icon}
                        </ListItemIcon>
                        <ListItemText
                          className={clsx(classes.listText, {
                            [classes.hide]: !open,
                          })}
                        >
                          <Typography style={{ fontSize: index > 7 ? '0.87rem' : '1rem' }}>
                            {list.name}
                          </Typography>
                        </ListItemText>
                        {/* {list.subItems && profile.account.account_type === "company" && (
                          <ListItemIcon className={classes.listIcon} style={{ minWidth: 'fit-content' }}>
                            {list.expanded
                              ? <ArrowUpIcon />
                              : <ArrowDownIcon />
                            }
                          </ListItemIcon>
                        )} */}
                      </ListItem>
                      {list.expanded && (
                        <Box style={{ margin: '0px 0px 20px' }}>
                          {list.subItems.map((item, subIndex) => (
                            <ListItem
                              button
                              key={subIndex + item.name}
                              className={props.location.pathname.startsWith(list.redirect) ? classes.activeLink : classes.listText}
                              style={{ padding: '0px 16px 0px 16px' }}
                              onClick={() => {
                                if (typeof item.redirect === 'function') {
                                  item.redirect();
                                } else if (typeof item.redirect === 'string') {
                                  handleMenuRedirect(item.redirect);
                                }
                              }}
                            >
                              <ListItemIcon className={classes.listIcon}>
                                {item.icon}
                              </ListItemIcon>
                              <ListItemText
                                className={clsx(classes.listText, {
                                  [classes.hide]: !open,
                                })}
                              >
                                <Typography style={{ fontSize: '12px', fontWeight: 500 }}>
                                  {item.name}
                                </Typography>
                              </ListItemText>
                            </ListItem>
                          ))}
                        </Box>
                      )}
                      {list.drawer && <Divider className={classes.divider} />}
                    </Box>
                  )
                })}
              </List>
              <List className={classes.inforWrapper}>
                <Button
                  variant='outlined'
                  color='secondary'
                  startIcon={<ShareAndEarnIcon />}
                  className={classes.shareEarn}
                  onClick={() => handleShareAndEarnPage()}
                >
                  Share & Earn
                </Button>
              </List>
              <List className={classes.listContainer} style={{ padding: '16px 10px 16px 10px' }}>
                {profile && (
                  <Box 
                    style={{ width: '100%', border: '1px solid #1E293B', borderRadius: '10px', display: 'flex', flexDirection: 'column', boxSizing: 'border-box', rowGap: '8px', padding: '8px', cursor: 'pointer' }}
                    onClick={() => setProfileModalVisible(true)}
                  >
                    <Box style={{ width: '100%', height: '50%', display: 'flex', alignItems: 'center', columnGap: '18px' }}>
                      <Avatar src={profile.user?.profilePhoto} alt={profile.account.fullName} style={{ width: '34px', height: '34px', border: '1px solid #475569' }} />
                      <Box style={{ width: '75%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', rowGap: '2px' }}>
                        <Typography noWrap style={{ fontSize: '14px', fontWeight: 500, lineHeight: '15px', color: '#FFFFFF' }}>
                          {profile.account?.fullName}
                        </Typography>
                        <Typography noWrap style={{ fontSize: '12px', fontWeight: 400, lineHeight: '14px', fontStyle: 'italic', color: '#94A3B8' }}>
                          {profile.user?.jobRole}
                        </Typography>
                      </Box>
                    </Box>
                    <Box style={{ width: '100%', height: '50%', display: 'flex', alignItems: 'center', columnGap: '17px' }}>
                      <Avatar src={profile.account?.logo} alt="logo" style={{ width: '34px', height: '34px', border: '1px solid #475569' }} />
                      <Box style={{ width: '75%' }}>
                        <Typography noWrap style={{ fontSize: '12px', fontWeight: 400, lineHeight: '14px', fontStyle: 'italic', color: '#94A3B8' }}>
                          {profile.account?.email}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                )}
              </List>
            </Drawer>
            <Box style={{ display: "flex", width: "100%", overflow: rightMenu ? 'hidden' : 'auto', zIndex: '9999' }}>
              <main className={classes.content}>
                {props.children}
              </main>
              {/* <RightMenu
                open={rightMenu}
                history={props.history}
                handleCloseRightMenu={handleRightMenu}
              />
              {!rightMenu && (
                <IconButton
                  onClick={handleRightMenu}
                  style={{
                    position: 'absolute',
                    right: '16px',
                    top: '7px',
                    zIndex: '9'
                  }}
                >
                  <RightBarIcon />
                </IconButton>
              )} */}
            </Box>
          </Box>
      }

      <SettingsModal
        open={profileModalVisible}
        onClose={() => setProfileModalVisible(false)}
        onReloadData={() => getProfile(true)}
      />

      {/* <ProfileModal
        visible={profileModalVisible}
        onReloadData={refetch}
        onClose={handleProfileModal}
      /> */}
    </>
  );
};

export default withRouter(PlatformLayout);