import React, { useEffect, useRef, useState } from 'react';
import moment from "moment";
import { Box, Button, Menu, MenuItem, Typography } from '@material-ui/core';
import { ReactComponent as GoldPlusIcon } from '../../../assets/icons/gold_plus_icon.svg';
import CircularProgress from '../CustomFields/CircularProgress';
import TopUpModal from './components/TopUpModal';
import PaymentModal from './components/PaymentModal';
import { getTopUps, getWallet, getWalletBalance } from '../../../redux/services/wallet';
import { getCurrentSubscription } from '../../../redux/services/subscriptions';
import { observableService } from '../../../services/observable';

import { useStyles } from './styles';
import { useSelector } from 'react-redux';

interface Props {
  type: 'ONBOARDING' | 'COPILOT' | 'TACTICAL_OUTREACH'
}

const LimitationWidget: React.FC<Props> = (props) => {
  // const [observable, setObservable] = useState<any>(null);
  const [hover, setHover] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showTopUpModal, setShowTopUpModal] = useState<boolean>(false);
  const [showPaymentModal, setShowPaymentModal] = useState<{ active: boolean, option: any }>({
    active: false,
    option: null
  });
  const [limits, setLimits] = useState<{ total: number, current: number }>({
    total: 0,
    current: 0
  });
  const [type, setType] = useState<'ONBOARDING' | 'COPILOT' | 'TACTICAL_OUTREACH' | null>(null);
  const [currentSubscription, setCurrentSubscription] = useState<any>(null);
  const [hideParent, setHideParent] = useState<boolean>(false);

  const open = Boolean(anchorEl);
  const classes = useStyles({ open });

  const profile = useSelector((state: any) => state.profile.metadata);

  useEffect(() => {
    if (profile) {
      handleGetWallet();
      handleGetCurrentSubscription();
    }
  }, [profile]);

  useEffect(() => {
    if (limits) {
      const observable = observableService.onEvent().subscribe((message) => {
        const coefficient = props.type === 'COPILOT'
          ? 0.5
          : props.type === 'TACTICAL_OUTREACH'
            ? 1
            : 6

        if (message === 'Refetch usage amount') {
          handleGetWallet();
        }

        if (message === 'Increase usage amount') {
          setLimits({
            ...limits,
            current: limits.current + coefficient > limits.total
              ? limits.total 
              : limits.current + coefficient
          })
        }

        if (message === 'Decrease usage amount') {
          setLimits({
            ...limits,
            current: limits.current - coefficient <= 0 
              ? 0 
              : limits.current - coefficient
          })
        }

        if (message.includes('Show top up modal')) {
          const type = message.replace('Show top up modal for ', '');

          setShowTopUpModal(true);
          setType(type);
        }
      });

      // setObservable(observable);

      return () => {
        observable.unsubscribe(); // Unsubscribe on component unmount
      };
    }
  }, [limits]);

  const handleGetWallet = async () => {
    const { data: wallet, error: walletError } = await getWallet(profile.account.uuid);
    const { data: balance, error: balanceError } = await getWalletBalance(profile.account.uuid);

    if (balance && !balanceError) {
      setLimits({
        total: balance.balance,
        current: wallet.reduce((total, obj) => total + obj.usedNumber, 0)
      })
    }
  };

  const handleGetCurrentSubscription = async () => {
    const { data, error } = await getCurrentSubscription();

    if (data && !error) {
      setCurrentSubscription(data);
    }
  }

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);

    handleGetWallet();
  };

  const getColorsAccordingToAmount = (total, current) => {
    const fiftyPercent = total * 0.5;
    const twentyFivePercent = total * 0.75;

    // If current is more than 50%
    if (current >= fiftyPercent && current < twentyFivePercent) {
      return {
        primary: '#FFE4A9', // Yellow color
        secondary: '#FFE4A933',
        background: '#000000',
        value: '#FFE4A9',
        totalValue: '#FFFFFF',
        additionalText: '#FFFFFF',
      };
    }

    // If current is more than 25% but less than or equal to 50%
    if (current >= twentyFivePercent) {
      return {
        primary: '#F87171', // Red color
        secondary: '#FFE4A933',
        background: '#000000',
        value: '#FEE2E2',
        totalValue: '#FFFFFF',
        additionalText: '#FFFFFF',
      };
    }

    // If current is 25% or less
    return {
      primary: '#34D399', // Green color
      secondary: '#FFE4A933',
      background: '#000000',
      value: '#D1FAE5',
      totalValue: '#FFFFFF',
      additionalText: '#FFFFFF',
    };
  };

  return (
    <>
      <Box
        className={`${classes.widget} ${open ? classes.widgetOpen : ''}`}
        onClick={handleOpen}
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <CircularProgress
          value={limits.current}
          totalValue={limits.total}
          showInnerContent={false}
          sizes={{
            width: '20px',
            height: '20px'
          }}
          colors={{
            primary: open || hover ? 'white' : '#CAB354',
            secondary: open || hover ? '#FFFFFF26' : '#CAB35426',
            background: open || hover ? 'black' : 'white',
          }}
          strokeWidth='4px'
        />
        <Typography style={{ display: 'flex', alignItems: 'flex-end', gap: '4px', fontSize: '14px', fontWeight: '600', lineHeight: '21px', whiteSpace: 'nowrap' }}>
          {limits.current}
          <Typography style={{ fontSize: '14px', fontWeight: '400', lineHeight: '21px', color: '#475569' }}>
            / {limits.total}
          </Typography>
        </Typography>
      </Box>

      <Menu
        className={classes.widgetMenu}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        getContentAnchorEl={null}
        open={open}
        onClose={() => setAnchorEl(null)}
      >
        <Typography style={{ fontSize: '10px', fontWeight: '500', lineHeight: '12px', color: '#CBD5E1' }}>
          Till {moment(currentSubscription?.validTill).format('DD MMM YYYY')}
        </Typography>
        <CircularProgress
          value={limits.current}
          totalValue={limits.total}
          additionalText={'Pebbles used'}
          sizes={{
            width: '135px',
            height: '135px'
          }}
          colors={getColorsAccordingToAmount(limits.total, limits.current)}
          strokeWidth='2.5px'
        />
        <Button
          fullWidth
          variant={'outlined'}
          className={classes.addButton}
          startIcon={<GoldPlusIcon />}
          onClick={() => {
            setAnchorEl(null)
            setShowTopUpModal(true)
          }}
        >
          Add
        </Button>
      </Menu>

      <TopUpModal
        open={showTopUpModal}
        type={props.type || type}
        limitReached={!!type}
        hideParent={hideParent}
        onClose={() => setShowTopUpModal(false)}
        showPaymentModal={(option) => {
          setShowPaymentModal({
            active: true,
            option
          })
        }}
      />

      <PaymentModal
        open={showPaymentModal.active}
        order={showPaymentModal.option}
        redirect={false}
        setHideParent={setHideParent}
        onClose={() => {
          setShowTopUpModal(false);
          setShowPaymentModal({
            active: false,
            option: null
          });

          observableService.sendEvent('Refetch usage amount');
        }}
      />
    </>
  );
};

export default LimitationWidget;
