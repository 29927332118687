import * as Yup from 'yup';

export const emailValidationRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const validationSchema = Yup.object({
  email: Yup.string()
    .email("Email address is not valid.")
    .required("This field is required"),
  name: Yup.string()
    .matches(/^[a-zA-Z\s\-']+$/, 'Full name can only contain letters, spaces, hyphens, and apostrophes')
    .test(
      'min-words',
      'Enter correct full name',
      value => value && value.split(' ').filter(word => word.length >= 3).length >= 2
    )
    .max(1000, 'Max 1000 symbols')
    .required("This field is required"),
  // project: Yup.number()
  //   .nullable()
  //   .required('This field is required'),
  permission: Yup.number()
    .nullable()
    .required('This field is required'),
});
