import React, { useState } from 'react';
import { Box, Typography, CircularProgress } from '@material-ui/core';
import { ReactComponent as EmailGeneratingLoader } from '../../../../assets/loaders/email_generating.svg';
import { ReactComponent as CheckCircleIcon } from '../../../../assets/icons/green_checkmark.svg';
import { GenerationStatusType } from '../../types';

import { useStyles } from './styles';

interface Props {
  generationStatus: GenerationStatusType;
}

interface StatusText {
  order: number;
  text: string;
}

const GenerationLoading: React.FC<Props> = ({ generationStatus }) => {
  const [textByStatus] = useState<Record<string, StatusText>>({
    SUBMITTING: {
      order: 1,
      text: 'Activating your AI agents',
    },
    SAVING: {
      order: 2,
      text: 'Fetching your organisational data',
    },
    GENERATION: {
      order: 3,
      text: 'Applying persuasion tactic',
    },
    OPTIMISING: {
      order: 4,
      text: 'Optimising lexical semantics',
    },
    READY: {
      order: 5,
      text: 'Your content ready',
    },
  });

  const classes = useStyles();

  return generationStatus !== 'NONE' ? (
    <Box className={classes.promptSavingWrapper}>
      <Box className={classes.promptSavingContent}>
        <Typography style={{ fontSize: '30px', fontWeight: '700', lineHeight: '32px', marginBottom: '40px' }}>
          Generating Results
        </Typography>
        {Object.entries(textByStatus).map(([key, value]) => (
          <Typography
            key={key}
            className={`
            ${classes.promptLabelDefault} 
            ${value.order === textByStatus[generationStatus]?.order
                ? value.order === Object.keys(textByStatus).length
                  ? classes.promptLabelCompleted
                  : classes.promptLabelCurrent
                : value.order <= textByStatus[generationStatus]?.order
                  ? classes.promptLabelCompleted
                  : classes.promptLabelPending
              }
          `}
          >
            {value.text}
            {value.order === textByStatus[generationStatus]?.order
              ? value.order === Object.keys(textByStatus).length
                ? <CheckCircleIcon 
                    width={'20px'}
                    height={'20px'}
                    className="animate__animated animate__fadeIn"
                  />
                : <CircularProgress
                    color="inherit"
                    className="animate__animated animate__fadeIn"
                    style={{
                      width: '20px',
                      height: '20px',
                    }}
                  />
              : value.order > textByStatus[generationStatus]?.order
                ? <CircularProgress
                    disableShrink
                    value={100}
                    variant="static"
                    color="inherit"
                    className="animate__animated animate__fadeIn"
                    style={{
                      width: '20px',
                      height: '20px',
                      color: '#475569'
                    }}
                  />
                : <CheckCircleIcon 
                    width={'20px'}
                    height={'20px'}
                    className="animate__animated animate__fadeIn"
                  />
            }
          </Typography>
        ))}
      </Box>
    </Box>
  ) : null;
};

export default GenerationLoading;
