import React, { useEffect, useState } from 'react';
import moment from "moment";
import { Box, Button, Typography, CircularProgress as LoadingCircularProgress } from '@material-ui/core';
import { ReactComponent as BlackPlusIcon } from '../../../../../assets/icons/white_plus_icon.svg';
import { ReactComponent as EmailIcon } from '../../../../../assets/icons/email_icon.svg';
import { ReactComponent as CopilotIcon } from '../../../../../assets/icons/copilot_icon.svg';
import { ReactComponent as OnboardingIcon } from '../../../../../assets/icons/onboarding_icon.svg';
import PaymentsHistory from '../PlanAndBilling/components/PaymentsHistory';
import CircularProgress from '../../../CustomFields/CircularProgress';
import PaymentModal from '../../../LimitationWidget/components/PaymentModal';
import TopUpModal from '../../../LimitationWidget/components/TopUpModal';
import { getWallet, getWalletBalance } from '../../../../../redux/services/wallet';
import { getCurrentSubscription } from '../../../../../redux/services/subscriptions';
import { observableService } from '../../../../../services/observable';
import { useSelector } from 'react-redux';

import { useStyles } from './styles';
import { enqueueSnackbar } from 'notistack';

interface Props {
  hideParent: boolean;
  setHideParent: (hide: boolean) => void;
}

const Wallet: React.FC<Props> = ({
  hideParent,
  setHideParent
}) => {
  const [wallet, setWallet] = useState<any>(null);
  const [balance, setBalance] = useState<any>(null);
  const [tab, setTab] = useState<string>('wallet');
  const [showPaymentModal, setShowPaymentModal] = useState<{ active: boolean, option: any }>({
    active: false,
    option: null
  });
  const [showTopUpModal, setShowTopUpModal] = useState<boolean>(false);
  const [currentSubscription, setCurrentSubscription] = useState<any>(null);

  const classes = useStyles();

  const profile = useSelector((state: any) => state.profile.metadata);
  const subscriptions = useSelector((state: any) => state.subscriptions.metadata);

  useEffect(() => {
    handleGetWallet();
    handleGetCurrentSubscription();
  }, [profile, subscriptions]);

  const handleGetWallet = async () => {
    const { data: wallet, error: walletError } = await getWallet(profile.account.uuid);
    const { data: balance, error: balanceError } = await getWalletBalance(profile.account.uuid);
    
    if (wallet && !walletError) {
      setWallet({
        ...wallet,
        limits: [
          {
            key: 'TACTICAL_OUTREACH',
            icon: <EmailIcon />,
            label: <>Generation <br /> emails</>,
            current: wallet.find(item => item.type === 'TACTICAL_OUTREACH').usedNumber,
          },
          {
            key: 'COPILOT',
            icon: <CopilotIcon />,
            label: <>Copilot <br /> messages</>,
            current: wallet.find(item => item.type === 'COPILOT').usedNumber,
          },
          {
            key: 'ONBOARDING',
            icon: <OnboardingIcon />,
            label: <>Onboarding <br /> auto-fills</>,
            current: wallet.find(item => item.type === 'ONBOARDING').usedNumber,
          },
        ]
      });
    }

    if (balance && !balanceError) {
      setBalance(balance);
    }
  }

  const handleGetCurrentSubscription = async () => {
    const { data, error } = await getCurrentSubscription();

    if (data && !error) {
      const targetSubscription = subscriptions.find(item => item.id === data.subscriptionId);

      setCurrentSubscription(targetSubscription);
    }
  }

  const getColorsAccordingToAmount = (total, current) => {
    const fiftyPercent = total * 0.5;
    const seventyFivePercent = total * 0.75;
  
    // If current is more than 50%
    if (current >= fiftyPercent && current < seventyFivePercent) {
      return {
        primary: '#F59E0B', // Yellow color
        secondary: '#FEF3C7',
        background: '#FFFBEC',
      };
    }
  
    // If current is more than 25% but less than or equal to 50%
    if (current >= seventyFivePercent) {
      return {
        primary: '#DC2626', // Red color
        secondary: '#FEE2E2',
        background: '#FFFBEC',
      };
    }
  
    // If current is 25% or less
    return {
      primary: '#059669', // Green color
      secondary: '#D1FAE5',
      background: '#FFFBEC',
    };
  };

  return (
    <>
      <Box className={classes.container}>
        {(tab === 'wallet' || tab === 'currentPlan') && (
          <>
            <Typography style={{ marginBottom: '12px', fontSize: '30px', fontWeight: '700', lineHeight: '32px', textAlign: 'center' }}>
              Wallet
            </Typography>
            {wallet && (
              <Typography style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '16px', textAlign: 'center', fontSize: '20px', fontWeight: '700', lineHeight: '24px' }}>
                Your balance
                <Typography>
                  |
                </Typography>
                <Typography style={{ fontSize: '20px', fontWeight: '600', lineHeight: '20px', color: '#D93855' }}>
                  {currentSubscription?.name}
                </Typography>
              </Typography>
            )}
            <Box className={classes.content}>
              {!wallet
                ? <Box
                    style={{
                      width: '100%',
                      height: '100%',
                      minHeight: '274px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <LoadingCircularProgress color="inherit" />
                  </Box>
                : <>
                    <Box className={classes.limitCardsWrapper}>
                      <Box style={{ width: '50%', display: 'flex', flexDirection: 'column', gap: '8px' }}>
                        <Typography style={{ display: 'flex', alignItems: 'center', fontSize: '16px', fontWeight: '600', lineHeight: '19px', color: '#475569' }}>
                          Pebbles&nbsp;
                          <Typography style={{ fontSize: '16px', fontWeight: '600', lineHeight: '19px', color: '#D97706' }}>
                            used
                          </Typography>
                        </Typography>
                        <Box style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: '4px' }}>
                          {wallet.limits.map((item, index) => (
                            <Box style={{ width: index === 2 ? '100%' : '49%', display: 'flex', flexDirection: 'column', gap: '8px', background: '#FFFFFF', border: '1px solid #CBD5E1', borderRadius: '20px', padding: '20px 15px 20px 20px' }}>
                              <Typography noWrap style={{ display: 'flex', alignItems: 'center', gap: '5px', fontSize: '10px', fontWeight: '500', lineHeight: '11px', color: '#475569' }}>
                                {item.key === 'COPILOT'
                                  ? <CopilotIcon style={{ minWidth: '8px' }} />
                                  : item.key === 'ONBOARDING'
                                    ? <OnboardingIcon style={{ minWidth: '9px' }} />
                                    : <EmailIcon style={{ minWidth: '10px' }} />
                                }
                                {item.key === 'COPILOT'
                                  ? 'Copilot prompts'
                                  : item.key === 'ONBOARDING'
                                    ? 'Onboarding edits'
                                    : 'Emails'
                                }
                              </Typography>
                              <Typography style={{ fontSize: '28px', fontWeight: '400', lineHeight: '33px' }}>
                                {item.current}
                              </Typography>
                            </Box>
                          ))}
                        </Box>
                      </Box>
                      <Box style={{ width: '50%', display: 'flex', flexDirection: 'column', gap: '8px' }}>
                        <Typography style={{ opacity: '0', display: 'flex', alignItems: 'center', fontSize: '16px', fontWeight: '600', lineHeight: '19px', color: '#475569' }}>
                          Pebbles&nbsp;
                          <Typography style={{ fontSize: '16px', fontWeight: '600', lineHeight: '19px', color: '#D97706' }}>
                            left
                          </Typography>
                        </Typography>
                        <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#FFFBEC', padding: '27px 20px', borderRadius: '20px', border: '1px solid #FFE4A9' }}>
                          <CircularProgress
                            value={wallet.limits.reduce((total, obj) => total + obj.current, 0)}
                            totalValue={balance?.balance || 0}
                            additionalText={'Pebbles used'}
                            sizes={{
                              width: '135px',
                              height: '135px',
                            }}
                            colors={getColorsAccordingToAmount(balance?.balance || 0, wallet.limits.reduce((total, obj) => total + obj.current, 0))}
                            strokeWidth={'3px'}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </>
              }
            </Box>
            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '8px' }}>
              <Button
                fullWidth
                color='secondary'
                variant='outlined'
                onClick={() => setTab('history')}
              >
                View payments history
              </Button>
              <Button
                fullWidth
                color='primary'
                variant='contained'
                startIcon={<BlackPlusIcon color='white' />}
                onClick={() => setShowTopUpModal(true)}
              >
                Add Pebbles
              </Button>
            </Box>
          </>
        )}
        {tab === 'history' && (
          <PaymentsHistory
            setTab={setTab}
          />
        )}
      </Box>

      <TopUpModal
        open={showTopUpModal}
        // type={showTopUpModal.type}
        limitReached={false}
        hideParent={hideParent}
        onClose={() => setShowTopUpModal(false)}
        showPaymentModal={(option) => {
          setShowPaymentModal({
            active: true,
            option
          })
        }}
      />

      <PaymentModal
        open={showPaymentModal.active}
        order={showPaymentModal.option}
        redirect={false}
        setHideParent={setHideParent}
        onClose={() => {
          setShowTopUpModal(false)
          setShowPaymentModal({
            active: false,
            option: null
          });

          observableService.sendEvent('Refetch usage amount');
          handleGetWallet();
        }}
      />
    </>
  );
};

export default Wallet;
