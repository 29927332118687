import { api, apiCall } from "../api";
import { ApiResponse } from "../types";

const subscriptionsAPI = api.injectEndpoints({
  endpoints: (builder) => ({
    getWallet: builder.query<any, any>({
      query: (uuid) => ({
        url: `/v1/license/wallet/account/${uuid}`,
        method: "GET"
      }),
    }),
    getWalletBalance: builder.query<any, any>({
      query: (uuid) => ({
        url: `/v1/license/wallet/account/${uuid}/balance`,
        method: "GET"
      }),
    }),
    getTopUps: builder.query<any, any>({
      query: () => ({
        url: `/v1/license/top-up/pricing`,
        method: "GET"
      }),
    }),
    createTopUpOrder: builder.query<any, any>({
      query: (body) => ({
        url: `/v1/license/orders`,
        method: "POST",
        body
      })
    }),
    createCustomTopUp: builder.query<any, any>({
      query: (body) => ({
        url: `/v1/license/top-up/custom`,
        method: "POST",
        body
      })
    })
  }),
  overrideExisting: true
});

// Create a function to call the API without using the hook
export const getWallet = async (uuid): Promise<any> => {
  return apiCall(subscriptionsAPI.endpoints.getWallet, uuid);
};

export const getWalletBalance = async (uuid): Promise<any> => {
  return apiCall(subscriptionsAPI.endpoints.getWalletBalance, uuid);
};

export const getTopUps = async (): Promise<any> => {
  return apiCall(subscriptionsAPI.endpoints.getTopUps);
};

export const createTopUpOrder = async (body): Promise<any> => {
  return apiCall(subscriptionsAPI.endpoints.createTopUpOrder, body);
};

export const createCustomTopUp = async (body): Promise<any> => {
  return apiCall(subscriptionsAPI.endpoints.createCustomTopUp, body);
};