import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { clearStorages, getUrlAndParams, getVisibleElementsByClassName, removeUrlParams } from '../../helpers/other';
import { Backdrop, Box, Button, CircularProgress, IconButton, Typography } from '@material-ui/core';
import SectionAccordion from './components/SectionAccordion';
import { Form, Formik } from 'formik';
import { getCampaignById, getCampaignEmailParameters, getCampaignParameters, getCampaignTemplate, regenerateEmail, saveCampaignEmailParameters, saveCampaignParameters, saveCampaignTemplate, triggerEmailGenerate, updateCampaign } from '../../redux/services/tactical-outreach';
import TooltipsModal from './components/TooltipModal';
import { enqueueSnackbar } from 'notistack';
import Header from './components/Header';
import { ReactComponent as PromptLoaderIcon } from '../../assets/icons/prompt_loading.svg';
import { ReactComponent as PromptReadyIcon } from '../../assets/icons/prompt_ready.svg';
import PromptPreparationVideo from '../../assets/videos/prompt_preparation_video.webm';
import ConfirmationModal from '../../components/main/ConfirmationModal';
import { Prompt } from 'react-router-dom';
import { generateInitialValue, generateParameters, generateValidationRules, generateYupSchema, isFieldNotEmpty } from './helpers';
import { convertMarkdownToHtml } from '../TacticalOutreachEmail/helpers';
import { observableService } from '../../services/observable';
import GenerationLoading from './components/GenerationLoading';
import { GenerationStatusType } from './types';

import { useStyles } from './styles';

interface Props {
  navigation: any;
  history: any;
}

const TacticalOutreachBuilder: React.FC<Props> = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [template, setTemplate] = useState<any>(null);
  const [validationSchema, setValidationSchema] = useState<any>({});
  const [tooltipMessage, setTooltipMessage] = useState<string | null>(null);
  const [templateMetadata, setTemplateMetadata] = useState<any>({
    name: {
      active: true,
      value: ''
    },
    description: {
      active: false,
      value: ''
    },
  });
  const [resetFormModal, setResetFormModal] = useState<boolean>(false);
  const [submitFormModal, setSubmitFormModal] = useState<boolean>(false);
  const [leavingModal, setLeavingModal] = useState<{ active: boolean, nextLocation: string | null }>({
    active: false,
    nextLocation: null
  });
  const [bloackNavigation, setBloackNavigation] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [generationStatus, setGenerationStatus] = useState<GenerationStatusType>('NONE');

  const classes = useStyles();
  const formRef = useRef<any>(null);

  useEffect(() => {
    setLoading(true);

    const { params } = getUrlAndParams();
    const template_id = params.get('template_id');
    const campaign_id = params.get('campaign_id');
    const target_email_id = params.get('target_email_id');

    if (campaign_id) {
      handleGetCampaignById(campaign_id, target_email_id);
    } else {
      handleGetCampaignData(template_id, null);
    }

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const handleBeforeUnload = (event) => {
    event.preventDefault();
    event.returnValue = '';
  };

  const handleGetCampaignById = async (campaign_id, target_email_id) => {
    setIsEdit(true);
    setBloackNavigation(true);

    const { data: metadata, error: metadataError } = await getCampaignById(campaign_id);
    const { data: parameters, error: parametersError } = target_email_id
      ? await getCampaignEmailParameters(target_email_id)
      : await getCampaignParameters(campaign_id)

    if (parameters && !parametersError) {
      await handleGetCampaignData(parameters.campaignTypeId, parameters.uiParameters);
      setLoading(true);

      if (metadata && !metadataError) {
        setTemplateMetadata({
          name: {
            active: false,
            value: metadata.name || ''
          },
          description: {
            active: false,
            value: metadata.description || ''
          },
        })
      }

      const intervalId = setInterval(() => {
        if (formRef?.current) {
          clearInterval(intervalId);

          if (parameters.uiParameters) {
            formRef.current.setValues(parameters.uiParameters);

            setLoading(false);
          } else {
            enqueueSnackbar(
              'Saved parameters not found',
              { variant: 'error' }
            )

            setBloackNavigation(false);

            setTimeout(() => {
              props.history.push('/tactical-outreach');
            }, 500);
          }
        }
      }, 1000);
    } else {
      enqueueSnackbar(
        'Campaign not found',
        { variant: 'error' }
      )

      setBloackNavigation(false);

      setTimeout(() => {
        props.history.push('/tactical-outreach');
      }, 500);
    }
  }

  const handleGetCampaignData = async (template_id, parameters) => {
    if (template_id) {
      setBloackNavigation(true);

      const { data, error } = await getCampaignTemplate(template_id);

      if (!error && data && Object.keys(data).length) {
        setTemplate({
          ...data,
          id: template_id
        });
        const shape = generateYupSchema(data, parameters);

        setValidationSchema(shape);

        if (data.subCategory === 'MULTI_TOUCH_CAMPAIGNS') {
          setLoading(true);

          const intervalId = setInterval(() => {
            if (formRef?.current) {
              clearInterval(intervalId);

              const newValues = {
                ...formRef?.current?.values
              };
              const newValidationSchema = {
                ...shape
              }

              data.sections.forEach((section) => {
                const subSections = section.attributes.filter(item => item.type === 'SEPARATED_QUESTION_ATTRIBUTE');

                subSections.forEach(subSection => {
                  const element = subSection.question.elements[0];

                  const nestedElement = element.attributes.find(item => item.type === 'ON_OPTION_CHILD_ATTRIBUTE')
                  const value = element.attributes.find(item => item.type === 'DEFINED_OPTION_ATTRIBUTE')?.option;
                  const name = `${section.id}-${subSection.question.id}-${element.id}`;

                  if (value) {
                    newValues[subSection.key] = {
                      ...newValues[subSection.key],
                      [name]: value
                    }
                    newValidationSchema[subSection.key] = Yup.object().shape({
                      [name]: generateValidationRules(`${subSection.key}.${name}`, subSection.question.elements[0], subSection.question),
                      [`${name}-${nestedElement.element.id}`]: generateValidationRules(`${subSection.key}.${name}`, nestedElement.element, subSection.question.elements[0])
                    }).required()
                  }
                })
              });

              formRef.current.setValues(newValues);
              setValidationSchema(newValidationSchema);

              setLoading(false);
              setBloackNavigation(true);
            }
          }, 1000);
        }
      } else {
        enqueueSnackbar(
          'Template not found',
          { variant: 'error' }
        )

        setBloackNavigation(false);

        setTimeout(() => {
          props.history.push('/tactical-outreach');
        }, 500);
      }

      setLoading(false);
    } else {
      enqueueSnackbar(
        'Template not found',
        { variant: 'error' }
      )

      setBloackNavigation(false);

      setTimeout(() => {
        props.history.push('/tactical-outreach');
      }, 500);
    }
  }

  const getInitialValues = () => {
    const initialValues = {};

    Object.entries(Yup.object().shape(validationSchema).describe().fields).forEach(([key, value]) => {
      initialValues[key] = generateInitialValue({ ...value, nullable: !!validationSchema[key]?._nullable })
    });

    return initialValues;
  };

  const getCompletedFields = (startName, values, uukey = null) => {
    const newValues = Object.fromEntries(Object.keys(uukey ? validationSchema[uukey].fields : validationSchema).map(key => {
      return uukey
        ? values[uukey]
          ? [key, values[uukey][key] || null]
          : [key, null]
        : [key, values[key] || null]
    }));
    const fieldsBySection = Object.fromEntries(Object.entries(newValues).filter(([key, value]) => key.startsWith(startName)));
    let fields = Object.fromEntries(Object.keys(fieldsBySection).map(key => [key, false]));
    let required = {};

    // check and set all not empty fields
    Object.entries(fieldsBySection).forEach(([key, value]: any) => {
      if (
        isFieldNotEmpty(value) &&
        !Object.keys(fieldsBySection).some(item =>
          item.startsWith(key) &&
          item !== key
        )
      ) {
        fields[key] = true
      } else {
        fields[key] = false
      }
    })

    // get only required fields from all non empty fields
    Object.entries(fieldsBySection).forEach(([key, value]: any) => {
      if (
        (uukey
          ? validationSchema[uukey]?.fields[key]?._exclusive?.required
          : validationSchema[key]?._exclusive?.required
        ) &&
        !Object.keys(fields).some(item =>
          item.startsWith(key) &&
          item !== key &&
          (uukey ? validationSchema[uukey]?.fields[key]?._exclusive?.required : validationSchema[key]?._exclusive?.required)
        )
      ) {
        required[key] = fields[key];
      }
    })

    // the result of sorting according to the principle: the key with the name 1-1-1-2 is higher in the list by 1-1-1
    fields = Object.fromEntries(Object.entries(fields).sort(([a], [b]) => a.split('-').map(Number).reduce((acc, n, i, arr) => acc || n - (b.split('-').map(Number)[i] || 0), 0)));
    required = Object.fromEntries(Object.entries(required).sort(([a], [b]) => a.split('-').map(Number).reduce((acc, n, i, arr) => acc || n - (b.split('-').map(Number)[i] || 0), 0)));

    let allAmount = 0;
    let requiredAmount = 0;

    Object.values(fields).forEach(item => {
      if (item === true) {
        allAmount = allAmount + 1
      }
    })

    Object.values(required).forEach(item => {
      if (item === true) {
        requiredAmount = requiredAmount + 1
      }
    })

    return {
      all: {
        fields: fields || {},
        amount: allAmount
      },
      required: {
        fields: required || {},
        amount: requiredAmount
      }
    }
  }

  const handleSaveCampaignTemplate = async (values: any, redirect?: string) => {
    if (templateMetadata.name.value.trim().length < 50 && templateMetadata.description.value.trim().length < 300) {
      setBloackNavigation(false);
      if (!redirect) {
        updateGenerationStatus('SUBMITTING');
      } else {
        setLoading(true);
      }

      const { params } = getUrlAndParams();
      const campaign_id = params.get('campaign_id');
      const target_email_id = params.get('target_email_id');

      const { data, error } = isEdit
        ? await (async function () {
            try {
              await updateCampaign({
                body: {
                  name: templateMetadata.name.value.trim(),
                  description: templateMetadata.description.value.trim(),
                },
                id: campaign_id,
              })

              return {
                data: { id: campaign_id },
                error: undefined
              }
            } catch (error) {
              return {
                data: undefined,
                error: error
              }
            }
          })()
        : await saveCampaignTemplate({
            name: templateMetadata.name.value?.trim() || '',
            description: templateMetadata.description.value?.trim() || '',
            typeId: +template.id
          })

      if (data && !error) {
        updateGenerationStatus('SAVING', 4000);

        const body = {
          campaignTypeId: +template.id,
          uiParameters: values,
          parameters: generateParameters(template, values)
        };

        if (target_email_id) {
          body['emailId'] = +target_email_id
        } else {
          body['campaignId'] = +data.id
        }

        const { data: answer, error: answerError } = target_email_id
          ? await saveCampaignEmailParameters(body)
          : await saveCampaignParameters(body);

        if (redirect) {
          enqueueSnackbar(
            isEdit
              ? 'Campaign updated successfully'
              : 'Campaign saved successfully',
            { variant: 'success' }
          )

          props.history.push(redirect);
        } else {
          if (answer && !answerError) {
            updateGenerationStatus('GENERATION', 4000);
            updateGenerationStatus('OPTIMISING', 8000);

            const { data: email, error: emailError } = target_email_id
              ? await regenerateEmail({
                  type: 'multi_touch',
                  emailId: +target_email_id,
                })
              : await triggerEmailGenerate({
                  campaignId: +data.id,
                  type: template.subCategory === 'MULTI_TOUCH_CAMPAIGNS'
                    ? 'multi_touch'
                    : 'single_touch'
                });

            if (email && !emailError) {
              updateGenerationStatus('READY');

              setTimeout(() => {
                observableService.sendEvent('Increase usage amount');

                if (template.subCategory === 'MULTI_TOUCH_CAMPAIGNS') {
                  enqueueSnackbar(
                    target_email_id
                      ? `New content generated`
                      : `Started to generate of ${email.metaData.total} emails. Please wait until all of them are generated`,
                    { variant: target_email_id ? 'success' : 'info' }
                  )
                }

                props.history.push(`/tactical-outreach/email?campaign_id=${+data.id}`);
              }, 2000);
            } else {
              if (emailError.status === 402) {
                observableService.sendEvent('Show top up modal for TACTICAL_OUTREACH');
              } else {
                enqueueSnackbar(
                  'Failed to request AI',
                  { variant: 'error' }
                )
              }

              setBloackNavigation(true);
              updateGenerationStatus('NONE');

              setIsEdit(true);

              props.history.replace(`/tactical-outreach/builder?campaign_id=${+data.id}${target_email_id ? `&email_id=${target_email_id}` : ''}`);
            }
          } else {
            setBloackNavigation(true);
            updateGenerationStatus('NONE');

            enqueueSnackbar(
              `Failed to ${isEdit ? 'update' : 'save'} parameters`,
              { variant: 'error' }
            )
          }
        }
      } else {
        setBloackNavigation(true);
        updateGenerationStatus('NONE');

        enqueueSnackbar(
          `Failed to ${isEdit ? 'update' : 'create'} template`,
          { variant: 'error' }
        )
      }
    } else {
      handleScrollToError();
    }
  }

  const handleBlockedNavigation = (nextLocation) => {
    setLeavingModal({
      active: true,
      nextLocation
    })

    return false;
  };

  const updateGenerationStatus = (status: GenerationStatusType, delay: number = 0) => {
    setTimeout(() => {
      setGenerationStatus((prevStatus) =>
        prevStatus === 'NONE'
          ? status === 'SUBMITTING'
            ? status
            : 'NONE'
          : status
      );
    }, delay);
  }

  const handleScrollToError = () => {
    setTimeout(() => {
      const elements = getVisibleElementsByClassName('Mui-error');

      if (elements.length) {
        elements[0].scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center'
        });
      }
    }, 500);
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  const handleBeforeSubmitCheck = (values) => {
    let result = {};

    const sections = template.sections.map(section => {
      if (!section.subSections.length) {
        return section.attributes.filter(item => item.type === 'SEPARATED_QUESTION_ATTRIBUTE').map(item => {
          return {
            ...item,
            id: section.id
          }
        })
      } else {
        return section
      }
    }).flat();

    sections.forEach(item => {
      const { required } = getCompletedFields(item.id, values, item.key);

      if (item.key) {
        result[item.key] = required.fields
      } else {
        result = {
          ...result,
          ...required.fields
        }
      }
    });

    const hasFalseValue = (result) => {
      return Object.entries(result).some(([key, value]) => {
        if (typeof value === 'object' && value !== null) {
          // Recursively check nested objects
          return hasFalseValue(value);
        }
        // Check if the value is explicitly false
        return value === false;
      });
    };

    if (hasFalseValue(result)) {
      setSubmitFormModal(true);
    } else {
      handleScrollToError();
    }
  }

  return (
    <>
      {generationStatus === 'NONE' && (
        <Prompt
          when={bloackNavigation && generationStatus === 'NONE'}
          message={(location) => handleBlockedNavigation(location)}
        />
      )}

      <Backdrop
        style={{ color: '#fff', zIndex: 99999999999999 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <GenerationLoading
        generationStatus={generationStatus}
      />

      <Box
        className={classes.container}
      // style={{
      //   opacity: loading ? '0.5' : '1'
      // }}
      >
        {template && Object.keys(validationSchema).length && (
          <Formik
            innerRef={formRef}
            initialValues={getInitialValues()}
            validationSchema={Yup.object().shape(validationSchema)}
            validateOnChange={true}
            validateOnBlur={false}
            isInitialValid={false}
            onSubmit={(values: any) => handleSaveCampaignTemplate(values)}
          >
            {({
              values,
              errors,
              touched,
              submitForm,
              validateField,
              validateForm,
              resetForm,
              setValues,
              setErrors,
              setFieldValue,
              setFieldError,
              setFieldTouched
            }) => (
              <>
                {formRef?.current && (
                  <Header
                    values={values}
                    template={template}
                    errors={errors}
                    touched={touched}
                    templateMetadata={templateMetadata}
                    validationSchema={validationSchema}
                    setTemplateMetadata={setTemplateMetadata}
                    getCompletedFields={getCompletedFields}
                  />
                )}

                <Form
                  translate={undefined}
                  onKeyDown={handleKeyDown}
                >
                  <Box className={classes.content}>
                    {template.sections.map((item, index) => (
                      <Box key={index}>
                        <SectionAccordion
                          section={item}
                          values={values}
                          errors={errors}
                          touched={touched}
                          validationSchema={validationSchema}
                          template={template}
                          validateForm={validateForm}
                          setValues={setValues}
                          setErrors={setErrors}
                          setFieldValue={setFieldValue}
                          setFieldError={setFieldError}
                          setFieldTouched={setFieldTouched}
                          setValidationSchema={setValidationSchema}
                          setTooltipMessage={setTooltipMessage}
                          getCompletedFields={getCompletedFields}
                        />
                      </Box>
                    ))}

                    <Box className={classes.footer}>
                      <Box className={classes.footerButtons}>
                        <Button
                          fullWidth
                          variant="contained"
                          color="secondary"
                          onClick={() => setResetFormModal(true)}
                        >
                          Reset
                        </Button>
                        <Button
                          fullWidth
                          variant="contained"
                          color="primary"
                          type="submit"
                          onClick={() => {
                            console.clear();
                            console.log('errors', errors);

                            handleBeforeSubmitCheck(values);
                          }}
                        >
                          Generate Content
                        </Button>
                      </Box>
                      <Typography style={{ width: '100%', textAlign: 'center', fontSize: '12px', fontWeight: '500', lineHeight: '14px', color: '#475569' }}>
                        In order to continue you have to complete all mandatory parameters.
                      </Typography>
                    </Box>
                  </Box>
                </Form>
              </>
            )}
          </Formik>
        )}
      </Box>

      <TooltipsModal
        open={!!tooltipMessage?.length}
        message={tooltipMessage}
        onClose={() => setTooltipMessage(null)}
      />

      <ConfirmationModal
        open={resetFormModal}
        title={"Are you sure you want to reset the form?"}
        description={"All entered data will be permanently lost"}
        onClose={() => setResetFormModal(false)}
        rejectBtnText={"No"}
        confirmBtnText={"Yes"}
        onReject={() => setResetFormModal(false)}
        onConfirm={() => {
          formRef.current.resetForm()
          setResetFormModal(false)
        }}
      />

      <ConfirmationModal
        open={submitFormModal}
        title={"Please, ensure the entered data is correct!"}
        onClose={() => {
          handleScrollToError()
          setSubmitFormModal(false)
        }}
        rejectBtnText={"Ok"}
        onReject={() => {
          handleScrollToError()
          setSubmitFormModal(false)
        }}
      />

      <ConfirmationModal
        open={leavingModal.active}
        title={isEdit
          ? 'Save last changes?'
          : 'Save changes and create a draft campaign?'
        }
        onClose={() => setLeavingModal({ active: false, nextLocation: null })}
        rejectBtnText={"Yes"}
        confirmBtnText={"No"}
        reversedButtons
        onReject={() => {
          setLoading(true);
          handleSaveCampaignTemplate(formRef.current.values, leavingModal.nextLocation);
          setLeavingModal({ active: false, nextLocation: null });
        }}
        onConfirm={async () => {
          setBloackNavigation(false);
          setTimeout(() => {
            props.history.push(leavingModal.nextLocation);
          }, 500);
        }}
      />
    </>
  );
};

export default TacticalOutreachBuilder;

