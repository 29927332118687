import { api, apiCall } from "../api";
import { store } from "../store";
import { ApiResponse } from "../types";
import { setSubscriptions } from "../features/subscriptions";

const subscriptionsAPI = api.injectEndpoints({
  endpoints: (builder) => ({
    getSubscriptions: builder.query<any, any>({
      query: () => ({
        url: `/v1/license/subscriptions`,
        method: "GET"
      }),
    }),
    getSubscriptionById: builder.query<any, any>({
      query: (id) => ({
        url: `/v1/license/subscriptions/${id}`,
        method: "GET"
      }),
    }),
    getPaymentsHistory: builder.query<any, any>({
      query: () => ({
        url: `/v1/license/payments/history`,
        method: "GET"
      }),
    }),
    createOrder: builder.query<any, any>({
      query: (body) => ({
        url: `/v1/license/orders`,
        method: "POST",
        body
      })
    }),
    getCurrentSubscription: builder.query<any, any>({
      query: () => ({
        url: `/v1/license/account_subscriptions`,
        method: "GET"
      }),
    }),
    cancelSubscription: builder.query<any, any>({
      query: () => ({
        url: `/v1/license/account_subscriptions`,
        method: "POST"
      })
    }),
    addPaymentMethod: builder.query<any, any>({
      query: () => ({
        url: `/v1/license/payments`,
        method: "POST"
      })
    }),
    renewSubscription: builder.query<any, any>({
      query: () => ({
        url: `/v1/license/account_subscriptions`,
        method: "PUT"
      })
    })
  }),
  overrideExisting: true
});

// Create a function to call the API without using the hook
export const getSubscriptions = async (forceFetch = false): Promise<any> => {
  const state = store.getState();

  // If there's cached data, return it instead of making a new request
  if (!forceFetch && state?.subscriptions?.metadata) {
    return { data: state.subscriptions.metadata };  // Return subscriptions data
  } else {
    // If no cached data, proceed with the API call
    const { data, error } = await store.dispatch(
      subscriptionsAPI.endpoints.getSubscriptions.initiate(null, { forceRefetch: true })
    );
    
    if (data && !error) {
      await store.dispatch(
        setSubscriptions(data)
      );
    }

    return { data, error };
  }
};

export const getSubscriptionById = async (id): Promise<any> => {
  return apiCall(subscriptionsAPI.endpoints.getSubscriptionById, id);
};

export const getPaymentsHistory = async (): Promise<any> => {
  return apiCall(subscriptionsAPI.endpoints.getPaymentsHistory);
};

export const createOrder = async (body): Promise<any> => {
  return apiCall(subscriptionsAPI.endpoints.createOrder, body);
};

export const getCurrentSubscription = async (): Promise<any> => {
  return apiCall(subscriptionsAPI.endpoints.getCurrentSubscription);
};

export const cancelSubscription = async (): Promise<any> => {
  return apiCall(subscriptionsAPI.endpoints.cancelSubscription);
};

export const addPaymentMethod = async (): Promise<any> => {
  return apiCall(subscriptionsAPI.endpoints.addPaymentMethod);
};

export const renewSubscription = async (): Promise<any> => {
  return apiCall(subscriptionsAPI.endpoints.renewSubscription);
};
