import React from 'react';
import { Field, Form, Formik } from "formik";
import { Modal, Box, IconButton, Typography, Button, Chip, InputLabel, FormControl, Select, MenuItem, Checkbox, ListItemText } from '@material-ui/core';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg';
import { ReactComponent as AtSignIcon } from '../../../../assets/icons/at_sign.svg';
import CheckBoxIcon from '../../../../assets/icons/checkbox.svg';
import CheckBoxCheckedIcon from '../../../../assets/icons/checkbox_checked.svg';
import { ReactComponent as ArrowDropDownIcon } from '../../../../assets/icons/dropdown_down.svg';
import { emailValidationRegex, validationSchema } from './validations';
import { PERMISSIONS } from '../../../../utils/enums';
import TextField from '../../../../components/main/CustomFields/TextField';
import Dropdown from '../../../../components/main/CustomFields/Dropdown';

import { useStyles } from './styles';

interface Props {
  open: boolean;
  onClose: () => void;
  onInvite: (values: any) => void;
}

const PROJECTS = [
  { value: 1, label: 'Coca Cola' },
  { value: 2, label: 'Apple' },
]

const InviteUserModal: React.FC<Props> = (props) => {
  const {
    open,
    onClose,
    onInvite
  } = props;

  const classes = useStyles();

  return (
    <Modal open={open} onClose={onClose}>
      <Box className={classes.modalBox}>
        <IconButton onClick={onClose} className={classes.closeButton}>
          <CloseIcon />
        </IconButton>
        <Typography className={classes.title}>
          Invite people to your team
        </Typography>
        <Box className={classes.form}>
          <Formik
            initialValues={{
              emailInput: "",
              email: "",
              name: "",
              // project: null,
              permission: null,
            }}
            validationSchema={validationSchema}
            validateOnChange={true}
            validateOnBlur={false}
            isInitialValid={false}
            onSubmit={(values: any) => onInvite(values)}
          >
            {({
              values,
              errors,
              touched,
              submitForm,
              validateField,
              setFieldValue,
              setFieldError
            }) => (
              <Form className={classes.form}>
                <TextField
                  required
                  name={'email'}
                  label={'Email'}
                  value={values['email']}
                  placeholder={'Provide an email address'}
                  error={(touched['email'] && errors['email']) as string}
                  onChange={(event) => setFieldValue('email', event.target.value)}
                />

                <TextField
                  name={'name'}
                  label={'Full Name'}
                  required
                  value={values['name']}
                  placeholder={'Enter full name'}
                  // type={'textarea'}
                  maxLength={1000}
                  error={(touched['name'] && errors['name']) as string}
                  onChange={(event) => setFieldValue('name', event.target.value)}
                />

                <Box style={{ display: 'flex', alignItems: 'flex-start', gap: '8px' }}>
                  <Dropdown
                    required
                    name={'project'}
                    label={'Project'}
                    value={PROJECTS.find(item => item.value === values['project'])?.label}
                    placeholder={'Choose project...'}
                    options={PROJECTS}
                    error={(touched['project'] && errors['project']) as string}
                    onChange={(event, value) => setFieldValue('project', event.target.value)}
                  />
                  <Dropdown
                    required
                    name={'permission'}
                    label={'Permission'}
                    value={values['permission']}
                    placeholder={'Choose permission...'}
                    options={Object.values(PERMISSIONS).map(item => ({ value: item, label: item }))}
                    error={(touched['permission'] && errors['permission']) as string}
                    onChange={(event, value) => setFieldValue('permission', event.target.value)}
                  />
                </Box>

                <Button
                  type="submit"
                  variant="contained"
                  startIcon={<AtSignIcon />}
                  className={classes.inviteButton}
                >
                  Send invitation(s)
                </Button>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    </Modal>
  );
};

export default InviteUserModal;
