import { api, apiCall } from "../api";
import { ApiResponse } from "../types";

const authenticationAPI = api.injectEndpoints({
  endpoints: (builder) => ({
    loginUser: builder.query<any, any>({
      query: (body) => ({
        url: `/auth/login`,
        method: "POST",
        body
      })
    }),
    signUpUser: builder.query<any, any>({
      query: (body) => ({
        url: `/accounts/register-email`,
        method: "POST",
        body
      })
    }),
    sendResetPasswordLink: builder.query<any, any>({
      query: (body) => ({
        url: `/auth/password-recovery`,
        method: "POST",
        body
      })
    }),
    saveNewPassword: builder.query<any, any>({
      query: ({ token, body }) => ({
        url: `/auth/password-recovery/${token}`,
        method: "PUT",
        body
      })
    }),
    confirmOtpCode: builder.query<any, any>({
      query: (body) => ({
        url: `/accounts/confirm-email`,
        method: "POST",
        body
      })
    }),
    updateAccountDetails: builder.query<any, any>({
      query: (body) => ({
        url: `/accounts/complete-registration`,
        method: "POST",
        body
      })
    }),
    getReferralCode: builder.query<any, any>({
      query: () => ({
        url: `/v1/license/referral-code`,
        method: "GET"
      })
    }),
    setReferralCodeAccount: builder.query<any, any>({
      query: (body) => ({
        url: `/v1/license/referrals`,
        method: "PUT",
        body
      })
    }),
    getReferralsAmount: builder.query<any, any>({
      query: (uuid) => ({
        url: `/v1/license/referrals/amount`,
        method: "GET"
      })
    })
  }),
  overrideExisting: true
});

// Create a function to call the API without using the hook
export const loginUser = async (body): Promise<any> => {
  return apiCall(authenticationAPI.endpoints.loginUser, body);
};

export const signUpUser = async (body): Promise<any> => {
  return apiCall(authenticationAPI.endpoints.signUpUser, body);
};

export const sendResetPasswordLink = async (body): Promise<any> => {
  return apiCall(authenticationAPI.endpoints.sendResetPasswordLink, body);
};

export const saveNewPassword = async ({ token, body }): Promise<any> => {
  return apiCall(authenticationAPI.endpoints.saveNewPassword, { token, body });
};

export const confirmOtpCode = async (body): Promise<any> => {
  return apiCall(authenticationAPI.endpoints.confirmOtpCode, body);
};

export const updateAccountDetails = async (body): Promise<any> => {
  return apiCall(authenticationAPI.endpoints.updateAccountDetails, body);
};

export const getReferralCode = async (): Promise<any> => {
  return apiCall(authenticationAPI.endpoints.getReferralCode, );
};

export const setReferralCodeAccount = async (body): Promise<any> => {
  return apiCall(authenticationAPI.endpoints.setReferralCodeAccount, body);
};

export const getReferralsAmount = async (): Promise<any> => {
  return apiCall(authenticationAPI.endpoints.getReferralsAmount);
};