export const aboutCompanyOnboradingData = (data) => {
  const mustHaveSectionList = data.find((item: any) => item.detailsType === 'MUST' && (item. objectType === 'COMPANY' || item.formObjectType === 'COMPANY'))?.attributes;
  const shouldHaveSectionList = data.find((item: any) => item.detailsType === 'SHOULD' && (item. objectType === 'COMPANY' || item.formObjectType === 'COMPANY'))?.attributes;
  const goodToHaveSectionList = data.find((item: any) => item.detailsType === 'GOOD' && (item. objectType === 'COMPANY' || item.formObjectType === 'COMPANY'))?.attributes;

  const mustHaveSection = mustHaveSectionList
    ? {
        whyExist: mustHaveSectionList.why_keywords,
        missionStatement: mustHaveSectionList.mission_statement,
        usp: mustHaveSectionList.usp,
        primaryFearQuestion1: mustHaveSectionList.primary_fear_questions_attributes[0].question_text,
        primaryFearQuestion2: mustHaveSectionList.primary_fear_questions_attributes[1].question_text,
        primaryFearQuestion3: mustHaveSectionList.primary_fear_questions_attributes[2].question_text,
        offering1: mustHaveSectionList.problem_statements_attributes[0].title,
        description1: mustHaveSectionList.problem_statements_attributes[0].description,
        offering2: mustHaveSectionList.problem_statements_attributes[1].title,
        description2: mustHaveSectionList.problem_statements_attributes[1].description,
        offering3: mustHaveSectionList.problem_statements_attributes[2].title,
        description3: mustHaveSectionList.problem_statements_attributes[2].description,
      }
    : {}

  const shouldHaveSection = shouldHaveSectionList 
    ? {
        companyEvalutionTime: [shouldHaveSectionList.evolution_timeline],
        companyHeadquarters: [shouldHaveSectionList.headquarters],
        companyAchivements: shouldHaveSectionList.company_achievements_attributes.map((item: { achievement: string }) => item.achievement),
        companyTestimonial: shouldHaveSectionList.company_testimonials_attributes.map((item: { testimonial: string }) => item.testimonial),
        keyCompanyFacts: shouldHaveSectionList.company_facts_attributes.map((item: { fact: string }) => item.fact)
      } 
    : {}

  const goodHaveSection = goodToHaveSectionList 
    ? {
        visionStatement: goodToHaveSectionList.vision_statements_attributes.map((item: { vision: string }) => item.vision),
        companyValue1: goodToHaveSectionList.company_values_attributes[0].company_value,
        companyValue2: goodToHaveSectionList.company_values_attributes[1].company_value,
        companyValue3: goodToHaveSectionList.company_values_attributes[2].company_value,
        companyDescription1: goodToHaveSectionList.company_values_attributes[0].description || '',
        companyDescription2: goodToHaveSectionList.company_values_attributes[1].description || '',
        companyDescription3: goodToHaveSectionList.company_values_attributes[2].description || '',
        primaryUtilityHook: goodToHaveSectionList.primary_utility_hooks_attributes.map((item: { utility_hook: string }) => item.utility_hook),
        mqlStatement: goodToHaveSectionList.mql_statements_attributes.map((item: { mql: string }) => item.mql),
        contributeToSocity: goodToHaveSectionList.contributation_to_societies_attributes.map((item: { contributation: string }) => item.contributation),
      } 
    : {}

  return {
    mustHaveSection,
    shouldHaveSection,
    goodHaveSection,
    // isMustHaveCompleted: checkForCompleted(mustHaveSection),
    isMustHaveCompleted: !!(
      mustHaveSection?.whyExist?.length >= 10 &&
      mustHaveSection?.missionStatement?.length &&
      mustHaveSection?.usp?.length &&
      mustHaveSection?.offering1?.length &&
      mustHaveSection?.description1?.length
    ),
    isShouldHaveCompleted: checkForCompleted(shouldHaveSection),
    isGoodHaveCompleted: checkForCompleted(goodHaveSection),
  }
}

export const yourApproachOnboradingData = (data) => {
  const mustHaveSectionList = data.find((item: any) => item.detailsType === 'MUST' && (item. objectType === 'APPROACH' || item.formObjectType === 'APPROACH'))?.attributes;
  const shouldHaveSectionList = data.find((item: any) => item.detailsType === 'SHOULD' && (item. objectType === 'APPROACH' || item.formObjectType === 'APPROACH'))?.attributes;
  const goodToHaveSectionList = data.find((item: any) => item.detailsType === 'GOOD' && (item. objectType === 'APPROACH' || item.formObjectType === 'APPROACH'))?.attributes;

  const mustHaveSection = mustHaveSectionList 
    ? {
        whyExist: mustHaveSectionList.how_keywords,
        solutionTitle1: mustHaveSectionList.solution_statement[0].title,
        solutionStatement1: mustHaveSectionList.solution_statement[0].description,
        solutionTitle2: mustHaveSectionList.solution_statement[1].title,
        solutionStatement2: mustHaveSectionList.solution_statement[1].description,
        solutionTitle3: mustHaveSectionList.solution_statement[2].title,
        solutionStatement3: mustHaveSectionList.solution_statement[2].description,
        stage1: mustHaveSectionList.high_level_approach[0].title,
        stageDescription1: mustHaveSectionList.high_level_approach[0].description,
        stage2: mustHaveSectionList.high_level_approach[1].title,
        stageDescription2: mustHaveSectionList.high_level_approach[1].description,
        stage3: mustHaveSectionList.high_level_approach[2].title,
        stageDescription3: mustHaveSectionList.high_level_approach[2].description,
      } 
    : {}

  const shouldHaveSection = shouldHaveSectionList 
    ? {
        processStep: shouldHaveSectionList.process.map((item: any) => item.title),
        processDescription: shouldHaveSectionList.process.map((item: any) => item.description),
        processSection: shouldHaveSectionList.process_testimonials_attributes.map((item: any) => item.testimonial),
        background: shouldHaveSectionList.success_stories[0].background,
        problem: shouldHaveSectionList.success_stories[1].problem,
        storySolution1: shouldHaveSectionList.solution[0].point,
        storySolution2: shouldHaveSectionList.solution[1].point,
        storySolution3: shouldHaveSectionList.solution[2].point,
        storyResult1: shouldHaveSectionList.results[0].point,
        storyResult2: shouldHaveSectionList.results[1].point,
        storyResult3: shouldHaveSectionList.results[2].point,
      } 
    : {}

  const goodHaveSection = goodToHaveSectionList 
    ? {
        segmentOverview: goodToHaveSectionList.segment_expertise_overiew[0],
        segmentTitle1: goodToHaveSectionList.key_segment_differentiators[0].title,
        segmentTitle2: goodToHaveSectionList.key_segment_differentiators[1].title,
        segmentTitle3: goodToHaveSectionList.key_segment_differentiators[2].title,
        segmentDescription1: goodToHaveSectionList.key_segment_differentiators[0].description,
        segmentDescription2: goodToHaveSectionList.key_segment_differentiators[1].description,
        segmentDescription3: goodToHaveSectionList.key_segment_differentiators[2].description,
        compareSection: goodToHaveSectionList.high_level_comparison,
      } 
    : {}

  return {
    mustHaveSection,
    shouldHaveSection,
    goodHaveSection,
    // isMustHaveCompleted: checkForCompleted(mustHaveSection),
    isMustHaveCompleted: !!(
      mustHaveSection?.whyExist?.length >= 10 && 
      mustHaveSection?.solutionStatement1?.length && 
      mustHaveSection?.solutionTitle1 && 
      mustHaveSection?.stageDescription1?.length && 
      mustHaveSection?.stage1
    ),
    isShouldHaveCompleted: checkForCompleted(shouldHaveSection),
    isGoodHaveCompleted: checkForCompleted(goodHaveSection),
  }
}

export const offeringsOnboradingData = (data) => {
  const mustHaveSectionData = data.find((item: any) => item.detailsType === 'MUST' && (item. objectType === 'OFFERINGS' || item.formObjectType === 'OFFERINGS'))?.attributes;
  const shouldHaveSectionData = data.find((item: any) => item.detailsType === 'SHOULD' && (item. objectType === 'OFFERINGS' || item.formObjectType === 'OFFERINGS'))?.attributes;
  const goodToHaveSectionData = data.find((item: any) => item.detailsType === 'GOOD' && (item. objectType === 'OFFERINGS' || item.formObjectType === 'OFFERINGS'))?.attributes;

  const mustHaveSection = mustHaveSectionData 
    ? {
        whyExist: mustHaveSectionData.what_keywords,
        productAndFeature: mustHaveSectionData.product_features_attributes,
        offeringServices: mustHaveSectionData.offering_services_attributes,
      } 
    : {}

  const shouldHaveSection = shouldHaveSectionData 
    ? {
        theTeam: shouldHaveSectionData.the_team,
        productTestimonials: shouldHaveSectionData.product_testimonials,
        performanceAnalystics: shouldHaveSectionData.performance_analystics,
      } 
    : {}

  const goodHaveSection = goodToHaveSectionData 
    ? {
        corporatePartners: [goodToHaveSectionData.corporate_partners],
        modularProducts: goodToHaveSectionData.modular_products,
        packagesAndPricing: goodToHaveSectionData.packages_and_pricing,
      } 
    : {}

  return {
    mustHaveSection,
    shouldHaveSection,
    goodHaveSection,
    // isMustHaveCompleted: checkForCompleted(mustHaveSection),
    isMustHaveCompleted: !!(
      mustHaveSection?.whyExist?.length >= 10 &&
      mustHaveSection?.productAndFeature?.length > 1 &&
      mustHaveSection?.offeringServices?.length > 1
    ),
    isShouldHaveCompleted: checkForCompleted(shouldHaveSection),
    isGoodHaveCompleted: checkForCompleted(goodHaveSection),
  }
}

export function checkForCompleted(data) {
  if (!Object.keys(data).length) {
    return false;
  } else {
    const { detailsType, objectType, ...restData } = data;

    const checkValue = (value) => {
      if (Array.isArray(value)) {
          return value.every(checkValue);
      } else if (typeof value === 'object' && value !== null) {
          return checkForCompleted(value);
      } else {
          return value !== "";
      }
    };

    return Object.values(restData).every(checkValue);
  }
}

export function checkPartiallyFilled(data) {
  if (!Object.keys(data).length) {
    return false;
  } else {
    const checkValue = (value) => {
      if (Array.isArray(value)) {
            return value.some(checkValue);
        } else if (typeof value === 'object' && value !== null) {
            return checkPartiallyFilled(value);
        } else {
            return value !== "";
        }
    };

    return Object.values(data).some(checkValue);
  }
};