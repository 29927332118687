import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  label: {
    display: 'flex',
    alignItems: 'center', 
    fontSize: '13px', 
    fontWeight: 500, 
    lineHeight: '16px', 
    color: '#0F172A', 
    marginTop: '5px',
    marginBottom: '0px',
    marginLeft: '2.5px'
  }
}));