import { api, apiCall } from "../api";
import { setIndustries, setJobRoles, setTeamSizes } from "../features/common";
import { store } from "../store";
import { ApiResponse } from "../types";

const commonAPI = api.injectEndpoints({
  endpoints: (builder) => ({
    getFileTypesData: builder.query<any, any>({
      query: (params) => ({
        url: "/file_types",
        method: "GET",
        params
      }),
      providesTags: ["Common"]
    }),
    getJobRoles: builder.query<any, any>({
      query: () => ({
        url: "/roles",
        method: "GET"
      }),
      providesTags: ["Common"]
    }),
    getIndustries: builder.query<any, any>({
      query: () => ({
        url: "/industries",
        method: "GET"
      }),
      providesTags: ["Common"]
    }),
    getTeamSize: builder.query<any, any>({
      query: () => ({
        url: "/company_sizes",
        method: "GET"
      }),
      providesTags: ["Common"]
    }),
  }),
  overrideExisting: true
});

export const { useGetFileTypesDataQuery } = commonAPI;

// Create a function to call the API without using the hook
export const getFileTypesData = async (params): Promise<any> => {
  return apiCall(commonAPI.endpoints.getFileTypesData, params);
};

export const getJobRoles = async (): Promise<any> => {
  const state = store.getState();

  // If there's cached data, return it instead of making a new request
  if (state?.common?.jobRoles) {
    return { data: state.common.jobRoles };  // Return profile data
  } else {
    // If no cached data, proceed with the API call
    const { data, error } = await store.dispatch(
      commonAPI.endpoints.getJobRoles.initiate(null, { forceRefetch: true })
    );
    
    if (data && !error) {
      await store.dispatch(
        setJobRoles(data)
      );
    }

    return { data, error };
  }
};

export const getIndustries = async (): Promise<any> => {
  const state = store.getState();

  // If there's cached data, return it instead of making a new request
  if (state?.common?.industries) {
    return { data: state.common.industries };  // Return profile data
  } else {
    // If no cached data, proceed with the API call
    const { data, error } = await store.dispatch(
      commonAPI.endpoints.getIndustries.initiate(null, { forceRefetch: true })
    );
    
    if (data && !error) {
      await store.dispatch(
        setIndustries(data)
      );
    }

    return { data, error };
  }
};

export const getTeamSize = async (): Promise<any> => {
  const state = store.getState();

  // If there's cached data, return it instead of making a new request
  if (state?.common?.teamSizes) {
    return { data: state.common.teamSizes };  // Return profile data
  } else {
    // If no cached data, proceed with the API call
    const { data, error } = await store.dispatch(
      commonAPI.endpoints.getTeamSize.initiate(null, { forceRefetch: true })
    );
    
    if (data && !error) {
      await store.dispatch(
        setTeamSizes(data)
      );
    }

    return { data, error };
  }
};