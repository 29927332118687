import { pathToRegexp } from "path-to-regexp";

// import EmailLoginBlock from "../blocks/email-account-login/main/EmailLoginBlock.web";
import Login from "../pages/Authentication/Login";
// import WelcomePage from "../blocks/email-account-login/main/WelcomePage.web";
import WelcomePage from "../pages/WelcomePage";
import ChooseyourOptions from "../blocks/email-account-login/main/ChooseyourOptions.web";
// import NewPassword from "../blocks/forgot-password/main/NewPassword.web";
import NewPassword from "../pages/Authentication/ForgotPassword/NewPassword";
import BulkUploading from "../blocks/bulkuploading/main/BulkUploadingCompanyInfo.web";
import BulkUploadingImport from "../blocks/bulkuploading/main/BulkUploadingCompanyImport.web";
import LandingPage from "../blocks/landingpage/main/LandingPage.web";
// import SignUpEmail from "../blocks/email-account-registration/main/SignUpEmail.web";
import SignUp from "../pages/Authentication/SignUp";
// import About from "../blocks/email-account-registration/main/About.web";
import AboutYourself from '../pages/Authentication/AboutYourself';
import CompanyAbout from "../blocks/email-account-registration/main/CompanyAbout.web";
import TellBitMoreBusiness from "../blocks/email-account-registration/main/TellBitMoreBusiness.web";
import BeforeWeStart from "../blocks/customisableusersubscriptions/main/BeforeWeStart.web";
// import SignUp from "../blocks/email-account-registration/main/SignUp.web";
import LetsGetStarted from "../blocks/email-account-registration/main/LetsGetStarted.web";
// import AccountDetail from "../blocks/email-account-registration/main/AccountDetail.web";
import AccountDetails from '../pages/Authentication/AccountDetails';
import CompanyDetail from "../blocks/email-account-registration/main/CompanyDetail.web";
import DescribleCompanyPlan from "../blocks/email-account-registration/main/DescribleCompanyPlan.web";
// import ForgotPassword from "../blocks/forgot-password/main/ForgotPassword.web";
import ResetPassword from "../pages/Authentication/ForgotPassword/ResetPassword";
// import PasswordRecovery from "../blocks/forgot-password/main/PasswordRecovery.web";
// import VerifySignUpCode from "../blocks/email-account-registration/main/VerifySignUpCode.web";
import VerifySignUpCode from "../pages/Authentication/VerifySignUpCode";
// import PasswordRecoverySuccess from "../blocks/forgot-password/main/PasswordRecoverySuccess.web";
import RecoverySuccess from "../pages/Authentication/ForgotPassword/RecoverySuccess";
import CompanySuccess from "../blocks/email-account-registration/main/CompanySuccess.web";
import ChooseYourPath from "../blocks/choose-your-path/main/ChooseYourPath.web";
import OnboardingLayout from "../blocks/email-account-registration/main/OnboardingLayout.web";
// import CompanyIntel from "../blocks/documentation/main/ComapnyIntel.web";
import CompanyIntel from "../pages/CompanyIntel";
import UserManagement from "../pages/UserManagement";
import TacticalOutreachBuilder from "../pages/TacticalOutreachBuilder";
import TacticalOutreachEmail from "../pages/TacticalOutreachEmail";
import TacticalOutreachList from "../pages/TacticalOutreachList";
import MyLibrary from "../pages/MyLibrary";
import Copilot from "../pages/Copilot";
import Subscriptions from "../pages/Subscriptions";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import TermsAndConditions from "../pages/TermsAndConditions";

import { LAYOUTS } from "../utils/enums";
import UserById from "../pages/UserManagement/components/UserById";
import ShareAndEarn from "../pages/ShareAndEarn";
import Cookies from "../pages/Cookies";

export const routeMap = {
  TacticalOutreach: {
    component: TacticalOutreachList,
    path: "/tactical-outreach",
    layout: LAYOUTS.PLATFORM_LAYOUT,
    exact: true,
    private: true
  },
  TacticalOutreachBuilder: {
    component: TacticalOutreachBuilder,
    path: "/tactical-outreach/builder",
    layout: LAYOUTS.PLATFORM_LAYOUT,
    exact: false,
    private: true
  },
  TacticalOutreachEmail: {
    component: TacticalOutreachEmail,
    path: "/tactical-outreach/email",
    layout: LAYOUTS.PLATFORM_LAYOUT,
    exact: false,
    private: true
  },
  Login: {
    component: Login,
    path: "/login",
    layout: LAYOUTS.CAROUSEL_LAYOUT,
    private: false
  },
  NewPassword: {
    component: NewPassword,
    path: "/new-password/:id",
    layout: LAYOUTS.CAROUSEL_LAYOUT,
    private: false
  },
  BulkUploading: {
    component: BulkUploading,
    path: "/upload-company-info",
    private: true
  },
  BulkUploadingImport: {
    component: BulkUploadingImport,
    path: "/additional-upload-company-info",
    private: true
  },
  LandingPage: {
    component: LandingPage,
    path: "/home",
    layout: LAYOUTS.PLATFORM_LAYOUT,
    private: true
  },
  SignUp: {
    component: SignUp,
    path: "/signup",
    layout: LAYOUTS.CAROUSEL_LAYOUT,
    private: false
  },
  LetsGetStarted: {
    component: LetsGetStarted,
    path: "/lets-get-started",
    layout: LAYOUTS.CAROUSEL_LAYOUT,
    private: false
  },
  // EmailAccountSignup: {
  //   component: SignUp,
  //   path: "/verify-code",
  //   layout: LAYOUTS.CAROUSEL_LAYOUT,
  //   private: false
  // },
  AccountDetails: {
    component: AccountDetails,
    path: "/account-details",
    layout: LAYOUTS.CAROUSEL_LAYOUT,
    private: false
  },
  AboutYourself: {
    component: AboutYourself,
    path: "/about-yourself",
    layout: LAYOUTS.CAROUSEL_LAYOUT,
    private: true
  },
  ChooseOption: {
    component: ChooseyourOptions,
    path: "/choose-option",
    layout: LAYOUTS.CAROUSEL_LAYOUT,
    private: true
  },
  BeforeWeStart: {
    component: BeforeWeStart,
    path: "/before-start",
    private: true
  },
  CompanyDetail: {
    component: CompanyDetail,
    path: "/company-describe",
    layout: LAYOUTS.CAROUSEL_LAYOUT,
    private: true
  },
  DescribleCompanyPlan: {
    component: DescribleCompanyPlan,
    path: "/describle-business-plan",
    layout: LAYOUTS.CAROUSEL_LAYOUT,
    private: true
  },
  CompanyAbout: {
    component: CompanyAbout,
    path: "/company-about",
    layout: LAYOUTS.CAROUSEL_LAYOUT,
    private: true
  },
  CompanySuccess: {
    component: CompanySuccess,
    path: "/company-success",
    private: true
  },
  TellBitMoreBusiness: {
    component: TellBitMoreBusiness,
    path: "/tell-more-business",
    layout: LAYOUTS.CAROUSEL_LAYOUT,
    private: true
  },
  Onboarding: {
    component: OnboardingLayout,
    path: "/onboarding",
    exact: true,
    layout: LAYOUTS.ONBOARDING_LAYOUT,
    private: true
  },
  OnboardingLayout: {
    component: OnboardingLayout,
    path: "/onboarding/:step",
    layout: LAYOUTS.ONBOARDING_LAYOUT,
    private: true
  },
  "/": {
    component: WelcomePage,
    path: "/",
    exact: true,
    layout: LAYOUTS.CAROUSEL_LAYOUT,
    private: false
  },
  // ForgotPassword: {
  //   component: ForgotPassword,
  //   path: "/forgot-password",
  //   layout: LAYOUTS.CAROUSEL_LAYOUT,
  //   private: false
  // },
  ResetPassword: {
    component: ResetPassword,
    path: "/forgot-password",
    layout: LAYOUTS.CAROUSEL_LAYOUT,
    private: false
  },
  // PasswordRecovery: {
  //   component: PasswordRecovery,
  //   path: "/password-recovery",
  //   layout: LAYOUTS.CAROUSEL_LAYOUT,
  //   private: false
  // },
  PasswordRecoverySuccess: {
    component: RecoverySuccess,
    path: "/password-recovery-success",
    private: false
  },
  VerifySignUpCode: {
    component: VerifySignUpCode,
    path: "/verify-signup-code",
    layout: LAYOUTS.CAROUSEL_LAYOUT,
    private: false
  },
  // ChooseYourPath: {
  //   component: ChooseYourPath,
  //   path: "/choose-your-path",
  //   layout: LAYOUTS.ONBOARDING_LAYOUT,
  //   private: true
  // },
  ChatHistory: {
    component: Copilot,
    path: "/copilot",
    layout: LAYOUTS.PLATFORM_LAYOUT,
    private: true
  },
  ChatHistoryId: {
    component: Copilot,
    path: "/chat/:id",
    layout: LAYOUTS.PLATFORM_LAYOUT,
    private: true
  },
  CompanyIntel: {
    component: CompanyIntel,
    path: "/company-intel",
    layout: LAYOUTS.PLATFORM_LAYOUT,
    private: true
  },
  ChooseYourPlan: {
    component: Subscriptions,
    path: "/choose-your-plan",
    layout: LAYOUTS.ONBOARDING_LAYOUT,
    private: true
  },
  Subscriptions: {
    component: Subscriptions,
    path: "/subscriptions",
    layout: LAYOUTS.ONBOARDING_LAYOUT,
    private: true
  },
  MyLibrary: {
    component: MyLibrary,
    path: "/my-library",
    layout: LAYOUTS.PLATFORM_LAYOUT,
    private: true
  },
  MyHistory: {
    component: MyLibrary,
    path: "/my-history",
    layout: LAYOUTS.PLATFORM_LAYOUT,
    private: true
  },
  UserManagement: {
    component: UserManagement,
    path: "/user-management",
    layout: LAYOUTS.PLATFORM_LAYOUT,
    private: true
  },
  UserById: {
    component: UserById,
    path: "/user/:id",
    layout: LAYOUTS.PLATFORM_LAYOUT,
    private: true
  },
  ShareAndEarn: {
    component: ShareAndEarn,
    path: "/share-and-earn",
    layout: LAYOUTS.PLATFORM_LAYOUT,
    private: true
  },
  PrivacyPolicy: {
    component: PrivacyPolicy,
    path: "/privacy-policy",
    private: false
  },
  TermsAndConditions: {
    component: TermsAndConditions,
    path: "/terms-and-conditions",
    private: false
  },
  Cookies: {
    component: Cookies,
    path: "/cookies",
    private: false
  },
};

export const matchRoute = (pathname, path) => {
  try {
    const keys = [];
    const regexp = pathToRegexp(path, keys);
    return regexp.test(pathname);
  } catch (error) {
    console.error(`Error matching route with path "${path}":`, error);
    return false;
  }
};
