import { BlockComponent } from "../../../framework/main/BlockComponent";
import { IBlock } from "../../../framework/main/IBlock";
import { Message } from "../../../framework/main/Message";
import MessageEnum, {
  getName
} from "../../../framework/main/Messages/MessageEnum";
import { runEngine } from "../../../framework/main/RunEngine";
import { v4 as uuidv4 } from "uuid";

import { WithStyles } from "@material-ui/core";
import StorageProvider from "../../../framework/main/StorageProvider";
import React from "react";
import { uploadOnboardingInformation } from "../../../redux/services/onboarding";
import { enqueueSnackbar } from "notistack";
import { getFileExtension } from "../../../helpers/file";
import { getFileTypesData, getIndustries, getJobRoles, getTeamSize } from "../../../redux/services/common";
import { clearStorages, getUrlAndParams } from "../../../helpers/other";
import { getCompanyIntelAttachments } from "../../../redux/services/company-intel";
import { updateProfile, getProfile } from "../../../redux/services/profile";
import { updateAccountDetails } from "../../../redux/services/authentication";
export const baseURL = require("../../../framework/main/config.js").baseURL;

export const configJSON = require("./config");

export interface Props extends WithStyles<any> {
  id: string;
  history: any;
  token?: string;
  view?: string;
  onClose?: () => void;
  triggerAutoFill?: () => void;
}

export interface WebFile extends File {
  id: string;
  information_type: number | string;
}

interface S {
  webFiles: WebFile[];
  message: string;
  error: string;
  infoDialog: boolean;
  highlightInfo: boolean;
  fileOptions: Array<{
    name: string;
    id: number;
  }>;
  loading: boolean;
  way: 'website' | 'documents';
}

interface SS {
  id: any;
}

export default class BulkUploadingCompanyInfoController extends BlockComponent<
  Props,
  S,
  SS
> {
  maxFileSize = 26214400;
  token: string = "";
  autofetch: boolean = false;
  profile: any = null;
  inputRef: any = null;
  formRef: any = null;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.inputRef = React.createRef();
    this.formRef = React.createRef();

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage)
    ];

    this.state = {
      webFiles: [],
      message: "",
      error: "",
      infoDialog: false,
      highlightInfo: true,
      fileOptions: [],
      loading: true,
      way: 'website',
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
  }

  async componentDidMount() {
    super.componentDidMount();

    const token = await StorageProvider.getItem("user-token");
    this.token = token;

    if (token) {
      this.getProfileData();
      this.fetchCompanyTypes();

      const { params } = getUrlAndParams();
      const autofetch = params.get('autofetch');

      this.autofetch = !!autofetch;

      if (autofetch) {
        this.fetchCompanyIntelAttachments();
      } else {
        this.setState({
          loading: false
        });
      }
    } else {
      this.setState({
        loading: false
      });
    }
  }

  getProfileData = async () => {
    const { data, error } = await getProfile(true);

    if (data && !error && this.formRef?.current) {
      this.profile = data;

      this.formRef?.current?.setFieldValue('website', data.account.webLink);
    }

    // this.handleGetProfileData(data || error);
  };

  fetchCompanyTypes = async () => {
    const { data } = await getFileTypesData({ file_type: "About your company" });

    if (Array.isArray(data)) {
      const fileOptions = data.map((type: any) => ({
        id: type.id,
        name: type.description,
      }));

      fileOptions.unshift({
        id: 0,
        name: "Choose file type",
      });

      this.setState({
        fileOptions
      });
    }
  };

  fetchCompanyIntelAttachments = async () => {
    const { data, error } = await getCompanyIntelAttachments({
      fileName: '',
      fileTypeDescription: 'COMPANY'
    });

    if (data && !error) {
      const webFiles = data.map(doc => ({
        id: doc.id,
        name: doc.filename,
        size: doc.attachmentSize,
        type: doc.contentType,
        lastModified: new Date(doc.updatedAt).getTime()
      }));
      const newFormValue = webFiles.reduce((acc, item) => {
        const matchingFile = data.find(file => file.id === item.id);
        const matchingOption = this.state.fileOptions.find(opt => opt.name === matchingFile.informationTypeDescription);

        if (matchingOption) {
          acc[item.id] = matchingOption.id;
        }

        return acc;
      }, {});

      // todo: complete the fetch uploaded files logic

      // this.formRef?.current?.setFieldValue("data", newFormValue);
      // this.onChangeFile(webFiles, []);
    }

    this.setState({
      loading: false
    });
  };

  onBack = () => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );

    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "BeforeWeStart"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    this.send(message);
  };

  async handleError() {
    this.setState({
      error: "An error occurred, please try again"
    });

    setTimeout(() => {
      this.setState({
        error: ""
      });
    }, 2000);
  }

  // async handleGetProfileData(responseJson: any) {
  //   if (responseJson.errors) {
  //     if (
  //       (Array.isArray(responseJson.errors) &&
  //         responseJson.errors.find((item) => "token" in item)) ||
  //       responseJson.errors === "Profile not present"
  //     ) {
  //       await clearStorages();

  //       const message: Message = new Message(
  //         getName(MessageEnum.NavigationMessage)
  //       );
  //       message.addData(getName(MessageEnum.NavigationTargetMessage), "/");
  //       message.addData(
  //         getName(MessageEnum.NavigationPropsMessage),
  //         this.props
  //       );
  //       this.send(message);
  //     } else {
  //       this.handleError();
  //     }
  //   }

  //   this.accountId = responseJson.attributes.account.id;
  // }

  handleFinishUploading = async () => {
    if (this.props.view === 'modal') {
      this.props.onClose();
      this.props.triggerAutoFill();
    } else {
      this.props.history.push('/onboarding');
      // await StorageProvider.removeItem('isEdit');

      // const message: Message = new Message(
      //   getName(MessageEnum.NavigationMessage)
      // );
      // message.addData(
      //   getName(MessageEnum.NavigationTargetMessage),
      //   "Onboarding"
      // );
      // message.addData(
      //   getName(MessageEnum.NavigationPropsMessage),
      //   this.props
      // );

      // this.send(message);
    }
  };

  handleFinishImport = async () => {
    this.props.history.push('/home');
    // const message: Message = new Message(
    //   getName(MessageEnum.NavigationMessage)
    // );
    // message.addData(
    //   getName(MessageEnum.NavigationTargetMessage),
    //   "LandingPage"
    // );
    // message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    // this.send(message);
  };

  handleGetCompanyTypes = (responseJson: any) => {
    if (!responseJson) {
      return;
    }

    const data = responseJson.map((type: any) => ({
      id: type.id,
      name: type.description
    }));

    data.unshift({
      id: 0,
      name: "Choose file type"
    });

    this.setState({
      fileOptions: data
    });
  };

  closeToast = () => {
    this.setState({
      message: ""
    });
  };

  skipNow = async () => {
    this.setState({
      loading: true
    });

    const { data: profile } = await getProfile();
    const { data: jobRoles } = await getJobRoles();
    const { data: industries } = await getIndustries();
    const { data: teamSizes } = await getTeamSize();

    await updateProfile({
      accountId: profile.account.uuid,
      companyName: profile.account.companyName,
      roleId: jobRoles.find(item => item.name === profile.user.jobRole).id,
      industryId: industries.find(item => item.industryName === profile.account.companyIndustry).id,
      gtmTeamSizeId: teamSizes.find(item => item.size === profile.account.gtmTeamSize).id,
      website: '',
      location: profile.account.countryName
    });

    await StorageProvider.removeItem("isEdit");

    this.props.history.push('/onboarding');

    // const message: Message = new Message(
    //   getName(MessageEnum.NavigationMessage)
    // );
    // message.addData(
    //   getName(MessageEnum.NavigationTargetMessage),
    //   "Onboarding"
    // );
    // message.addData(
    //   getName(MessageEnum.NavigationPropsMessage),
    //   this.props
    // );

    // this.send(message);

    this.setState({
      loading: false
    });
  };

  uploadMultipleFile = async (values) => {
    if (this.state.way === 'website') {
      this.setState({
        loading: true
      });

      const { data: profile } = await getProfile();
      const { data: jobRoles } = await getJobRoles();
      const { data: industries } = await getIndustries();
      const { data: teamSizes } = await getTeamSize();

      await updateProfile({
        accountId: profile.account.uuid,
        companyName: profile.account.companyName,
        roleId: jobRoles.find(item => item.name === profile.user.jobRole).id,
        industryId: industries.find(item => item.industryName === profile.account.companyIndustry).id,
        gtmTeamSizeId: teamSizes.find(item => item.size === profile.account.gtmTeamSize).id,
        website: values.website,
        location: profile.account.countryName
      });

      this.handleFinishUploading();

      this.setState({
        loading: false
      });
    } else {
      const files = this.state.webFiles;

      if (!files.length || files.length > 3) {
        const message = !files.length ? "Please attach file" : "You can upload 3 documents max"

        this.setState({
          error: message
        });

        setTimeout(() => {
          this.setState({
            error: ""
          });
        }, 5000);

        // enqueueSnackbar(
        //   message,
        //   { variant: 'error' }
        // );

        return;
      }

      this.setState({
        loading: true
      });

      const promises = files.map(async file => {
        return await uploadOnboardingInformation({
          file,
          type: this.state.fileOptions.find(item => item.id === file.information_type).name
        });
      });

      Promise.allSettled(promises).then(res => {
        const erroneous = res.filter((item: any) => 'error' in item.value);
        const successful = res.filter((item: any) => 'data' in item.value && !('error' in item.value));

        if (successful.length) {
          enqueueSnackbar(
            `${successful.length} document(s) uploaded successfully`,
            { variant: 'success' }
          );
        }

        if (erroneous.length) {
          enqueueSnackbar(
            `${erroneous.length} document(s) failed in the uploading process`,
            { variant: 'error' }
          );
        }

        this.setState({
          loading: false
        });

        this.handleFinishUploading();
      });
    }
  };

  importMultipleFile = () => {
    const importFiles = this.state.webFiles;

    if (!importFiles.length || importFiles.length > 3) {
      const message = !importFiles.length ? "Please attach file" : "You can upload 3 documents max"

      this.setState({
        error: message
      });

      setTimeout(() => {
        this.setState({
          error: ""
        });
      }, 5000);

      // enqueueSnackbar(
      //   message,
      //   { variant: 'error' }
      // );

      return;
    }

    this.setState({
      loading: true
    });

    const promises = importFiles.map(async file => {
      return await uploadOnboardingInformation({
        file,
        type: this.state.fileOptions.find(item => item.id === file.information_type).name
      });
    });

    Promise.allSettled(promises).then(res => {
      const erroneous = res.filter((item: any) => 'error' in item.value);
      const successful = res.filter((item: any) => 'data' in item.value && !('error' in item.value));

      if (successful.length) {
        enqueueSnackbar(
          `${successful.length} document(s) uploaded successfully`,
          { variant: 'success' }
        );
      }

      if (erroneous.length) {
        enqueueSnackbar(
          `${erroneous.length} document(s) failed in the uploading process`,
          { variant: 'error' }
        );
      }

      this.setState({
        loading: false
      });

      this.handleFinishImport();
    });
  };

  removeFileWeb = (fileId: string) => {
    const input = this.inputRef.current;

    if (input) {
      input.value = null;
    }

    this.setState({
      webFiles: this.state.webFiles.filter((_file) => _file.id !== fileId)
    });
  };

  onChangeFile = (files?: FileList, erroneous?: { file: File, message: string }[]) => {
    if (erroneous?.length) {
      this.setState({
        error: `${erroneous.length} file${erroneous.length > 1 ? 's' : ''} failed`
      })
    } else if (this.state.webFiles.length >= 3) {
      this.setState({
        error: 'You can upload 3 documents max'
      })
    } else {
      this.setState({
        error: ''
      })
    }

    const tempFile: WebFile[] = [];

    Array.from(files).slice(0, !this.state.webFiles.length ? 3 : Math.abs(this.state.webFiles.length - 3)).forEach((_file) => {
      const file: any = _file;
      file.information_type = 0;

      tempFile.push(file);
    });

    const newList = [...this.state.webFiles, ...tempFile];

    this.setState({
      webFiles: newList
    });
  };

  // handleDrag = (e: any) => {
  //   e.preventDefault();
  //   e.stopPropagation();

  //   const dropZone = e.currentTarget;

  //   if (e.type === "dragenter" || e.type === "mouseover" || e.type === "mouseenter") {
  //     dropZone.style.border = "2px dashed #3A84C9";
  //     dropZone.style.background = "#3A84C90D";
  //     dropZone.style.cursor = "pointer";
  //   }

  //   if (e.type === "dragleave" || e.type === "mouseleave") {
  //     dropZone.style.border = "1px solid #CBD5E1";
  //     dropZone.style.background = "transparent";
  //     dropZone.style.cursor = "pointer";
  //   }
  // };

  // convertByteToReadable = (bytes: number) => {
  //   const kilobyte = 1024;
  //   const megabyte = kilobyte * 1024;

  //   if (bytes >= kilobyte && bytes < megabyte) {
  //     return Math.round(bytes / kilobyte) + " KB";
  //   } else if (bytes >= megabyte) {
  //     return Math.round(bytes / megabyte) + " MB";
  //   } else {
  //     return bytes + " Bytes";
  //   }
  // };

  // triggers when file is dropped
  // handleDrop = (e: any) => {
  //   e.preventDefault();
  //   e.stopPropagation();

  //   const dropZone = e.currentTarget;

  //   dropZone.style.border = "1px solid #CBD5E1";
  //   dropZone.style.background = "transparent";
  //   dropZone.style.cursor = "pointer";

  //   if (e.dataTransfer.files.length) {
  //     const files = e.dataTransfer.files;

  //     if (files) {
  //       Array.from(files).forEach((file) => {
  //         (file as any).id = uuidv4();
  //       });

  //       this.onChangeFile(files);
  //     }
  //   }
  // };

  getColor = (name: string) => {
    if (name.includes(".pdf")) {
      return "#E04E4E";
    }

    if (name.includes(".docx")) {
      return "#3166ED";
    }

    return "#00B84A";
  };

  onCancel = () => {
    this.props.history.push('/home');
    // const message: Message = new Message(
    //   getName(MessageEnum.NavigationMessage)
    // );

    // message.addData(
    //   getName(MessageEnum.NavigationTargetMessage),
    //   "LandingPage"
    // );
    // message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    // this.send(message);
  };

  toggleInfoDialog = () => {
    this.setState({
      infoDialog: !this.state.infoDialog
    });
  };

  toggleHighlightInfo = () => {
    this.setState({
      highlightInfo: !this.state.highlightInfo
    });
  };

  getFileType = (type: string) => {
    const splitType = type.split("/");
    if (splitType.length < 2) {
      return "UNKNOWN";
    }

    if (splitType[1].length > 10) {
      return splitType[1].substring(0, 10).toLocaleUpperCase() + "...";
    }

    return splitType[1].toLocaleUpperCase();
  };

  handleChangeFileInfo = (fileId: string, value: number) => {
    const index = this.state.webFiles.findIndex((file) => file.id === fileId);

    if (index < 0) {
      return;
    }

    const newWebFiles = [...this.state.webFiles];

    newWebFiles[index].information_type = value;

    this.setState({
      webFiles: newWebFiles
    });
  };

  handleChooseWay = (way) => {
    this.setState({
      way
    })
  }
}
