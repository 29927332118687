import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  metadata: null,
};

export const subscriptionsSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setSubscriptions: (state, action) => {
      state.metadata = action.payload;
    },
  },
});

export const {
  setSubscriptions
} = subscriptionsSlice.actions;

export default subscriptionsSlice.reducer;