import React from 'react';

import TextField from '../../../../../../components/main/CustomFields/TextField';
import { Typography } from '@material-ui/core';
import { findElementByName } from '../../../../helpers';

import { useStyles } from './styles';

interface Props {
  parent?: any;
  item: any;
  name: string;
  values: any;
  errors: any;
  touched: any;
  template: any;
  uukey: string;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  setFieldError: (field: string, message: string) => void;
  setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void;
}

const RenderTextArea: React.FC<Props> = ({
  parent,
  item,
  name,
  values,
  template,
  uukey,
  setFieldValue,
  setFieldError,
  setFieldTouched,
  ...props
}) => {
  const classes = useStyles();

  const value = uukey
    ? values[uukey][name]
    : values[name]
  const touched = uukey
    ? props.touched[uukey] && props.touched[uukey][name]
    : props.touched[name]
  const error = uukey
    ? props.errors[uukey] && props.errors[uukey][name]
    : props.errors[name]
  const minLength = item.attributes.find(item => item.type === 'MIN_LENGTH_ATTRIBUTE')?.value || 0;
  const maxLength = item.attributes.find(item => item.type === 'MAX_LENGTH_ATTRIBUTE')?.value;
  const defaultPaceholder = item.attributes.find(item => item.type === 'PLACEHOLDER_ATTRIBUTE')?.text;
  const placeholder = item.attributes.find(item => (
    item.type === 'RESET_PLACEHOLDER_UNDER_CONDITION_ATTRIBUTE' && values[Object.values(item.elementReference).join('-')] === item.option
  ))?.placeholder;
  const label = item.attributes.find(item => item.type === 'TITLE_ATTRIBUTE')?.text;
  const isOptional = item.attributes.find(item => item.type === 'OPTIONAL_ATTRIBUTE');

  const onValueChange = (event) => {
    if (uukey) {
      setFieldValue(uukey, {
        ...values[uukey],
        [name]: event.target.value
      })
    } else {
      setFieldValue(name, event.target.value)
    }
  }

  const onBlurField = () => {
    const setErrorAndTouched = (key, error, touchedValue = true) => {
      setFieldError(key, error);
      setFieldTouched(key, touchedValue);
    };
  
    const getErrorObject = (key, error) => ({
      ...props.errors[key],
      [name]: error
    });
  
    const getTouchedObject = (key) => ({
      ...props.touched[key],
      [name]: true
    });
  
    const key = uukey || name;
  
    if (!isOptional && !value?.length) {
      const error = uukey
        ? getErrorObject(uukey, 'This field is required')
        : 'This field is required';
  
      setErrorAndTouched(key, error, uukey ? getTouchedObject(uukey) : true);
      return;
    }
  
    if (!!minLength || !!maxLength) {
      const lengthError = value?.length < minLength
        ? `Min ${minLength} ${minLength > 1 ? "characters" : "character"}`
        : value?.length > maxLength
          ? `Max ${maxLength} ${maxLength > 1 ? "characters" : "character"}`
          : undefined;
  
      if (lengthError) {
        const error = uukey
          ? getErrorObject(uukey, lengthError)
          : lengthError;
  
        setErrorAndTouched(key, error, uukey ? getTouchedObject(uukey) : true);
      }
    }
  };

  return (
    <>
      {parent && (
        <Typography className={classes.label}>
          {label || 'Provide more context'}
          &nbsp;
          {!!minLength && (
            <Typography style={{ fontSize: '12px', fontWeight: '400', lineHeight: '14px', color: '#475569' }}>
              (min {minLength} characters)
              &nbsp;&nbsp;
            </Typography>
          )}
          {isOptional
            ? <Typography style={{ fontSize: '12px', fontWeight: '400', lineHeight: '13px', color: '#475569' }}>
                (optional)
              </Typography>
            : <Typography style={{ fontSize: '16px', fontWeight: '500', lineHeight: '17px', color: '#DC2626' }}>
                *
              </Typography>
          }
        </Typography>
      )}
      <TextField
        name={name}
        value={value}
        placeholder={placeholder || defaultPaceholder || ""}
        type={'textarea'}
        minLength={minLength}
        maxLength={maxLength}
        error={parent
          ? error
          : touched && error
        }
        onChange={(event) => onValueChange(event)}
        onBlur={() => onBlurField()}
      />
    </>
  );
};

export default RenderTextArea;
