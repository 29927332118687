import React from "react";
import { ReduxProvider } from "./redux";
import { ThemeProvider } from "./theme";
import { PersistProvider } from "./persist";
import { LayoutProvider } from "./layout";
import { NotificationProvider } from "./notification";
import { AuthProvider } from "./auth";
import { ContextProvider } from "./context";

export const Providers = ({ children }) => {
  return (
    <ReduxProvider>
      <ContextProvider>
        <ThemeProvider>
          <NotificationProvider>
            <AuthProvider>
              <LayoutProvider>
                <PersistProvider>
                  {children}
                </PersistProvider>
              </LayoutProvider>
            </AuthProvider>
          </NotificationProvider>
        </ThemeProvider>
      </ContextProvider>
    </ReduxProvider>
  );
};
