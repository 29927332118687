import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  jobRoles: null,
  industries: null,
  teamSizes: null
};

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setJobRoles: (state, action) => {
      state.jobRoles = action.payload;
    },
    setIndustries: (state, action) => {
      state.industries = action.payload;
    },
    setTeamSizes: (state, action) => {
      state.teamSizes = action.payload;
    },
  },
});

export const {
  setJobRoles,
  setIndustries,
  setTeamSizes
} = commonSlice.actions;

export default commonSlice.reducer;