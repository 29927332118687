import { Message } from "../../../framework/main/Message";
import { BlockComponent } from "../../../framework/main/BlockComponent";
import { runEngine } from "../../../framework/main/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/main/Messages/MessageEnum";
import { WithStyles } from "@material-ui/core";
import { AboutValues } from "./CompanyAbout.web";
import StorageProvider from '../../../framework/main/StorageProvider';
import { countryFile } from './assets'
import { ONBOARDING_STEPS, STEPS_TYPE } from "../../../utils/enums";
import { enqueueSnackbar } from "notistack";
import { jsonToArray } from "../../../helpers/other";
export const configJSON = require("./config");

export interface Props extends WithStyles<any> {
  navigation?: any;
  id?: string;
  email?: string;
  token?: string;
}

export interface S {
  companySize: any[];
  industry: any[];
  roleList: any[];
  locationList: any[];
  isLoad: boolean;
  hereAbout: any[];
  gtmChallenge: any[];
  gtmChallengeValue: any[];
  displayCount: number;
  initialDisplayCount: number;
}

export interface SS {
  id: any;
}

export default class AccountDetailController extends BlockComponent<
  Props,
  S,
  SS
> {
  fetchCompaniesId: string = "";
  fetchLocationId: string = "";
  fetchIndustriesId: string = "";
  apiPostAccountId: string = "";
  apiCompanyAboutPost: string = "";
  fetchCompanyHereAboutId: string = "";
  fetchGtmChallengeId: string = "";
  roleMessageId: string = '';
  targetScreen: string = '';
  token: string;

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    const countries = jsonToArray(countryFile)

    this.state = {
      companySize: [{ id: "", size: "Select response" }],
      industry: [{ id: "", industry_name: "Select response" }],
      hereAbout: [{ id: "", name: "Select response" }],
      isLoad: false,
      roleList: [
        { id: "", name: "Select response" },
      ],
      locationList: countries,
      gtmChallenge: [],
      gtmChallengeValue: [],
      initialDisplayCount: 2,
      displayCount: 2,
    };
  }


  onBackCreatAccount = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'AccountDetails'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(message);
  }

  handleRoleListAPI = (responseJson: any) => {
    if (responseJson.errors) {
      const errors = this.parseApiErrorResponse(responseJson);

      enqueueSnackbar(
        `${errors}`,
        { variant: 'error' }
      )
    } else if (responseJson?.data?.roles) {
      this.setState({ roleList: [...this.state.roleList, ...responseJson?.data?.roles] })
    }
  }

  componentDidMount = async () => {
    super.componentDidMount();

    const token = await StorageProvider.getItem("user-token");
    this.token = token;

    // if (!this.token) {
    //   const msg: Message = new Message(
    //     getName(MessageEnum.NavigationWelcomeScreenMessage)
    //   );

    //   msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    //   this.send(msg);
    //   return;
    // }

    const targetScreen = await StorageProvider.getItem("target-screen");
    this.targetScreen = targetScreen;
    this.fetchCompanySize()
    this.fetchIndustries()
    this.fetchLocationList()
    this.fetchCompanyHereAbout()
    this.fetchGtmChallenges()
    this.fetchRoleList();
  }

  async receive(from: string, message: Message) {
    this.setState({ isLoad: false })
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    switch (apiRequestCallId) {
      case this.fetchCompaniesId:
        this.handleCompanyAPI(responseJson);
        break;
      case this.fetchIndustriesId:
        this.handleIndustyAPI(responseJson);
        break;
      case this.fetchCompanyHereAboutId:
        this.handleCompanyHereAPI(responseJson);
        break;
      case this.apiPostAccountId:
        if (!responseJson.errors) {
          window.localStorage.clear();
          window.localStorage.setItem(STEPS_TYPE.ONBOARDING, ONBOARDING_STEPS.CHOOSE_PATH);
          StorageProvider.setItem("isBusiness", "");
          const message: Message = new Message(getName(MessageEnum.NavigationMessage))
          message.addData(
            getName(MessageEnum.NavigationTargetMessage),
            'ChooseYourPlan'
            // 'ChooseYourPath'
          );
          message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
          this.send(message);
        } else {
          //Check Error Response
          const errors = this.parseApiErrorResponse(responseJson);

          enqueueSnackbar(
            `${errors}`,
            { variant: 'error' }
          )
        }
        break;
      case this.fetchLocationId:
        this.handleLocationListAPI(responseJson);
        break;
      case this.roleMessageId:
        this.handleRoleListAPI(responseJson);
        break;
      case this.fetchGtmChallengeId:
        this.handleGtmChallengesListAPI(responseJson);
        break;
      case this.apiCompanyAboutPost:
        if (!responseJson.errors) {
          window.localStorage.clear();
          window.localStorage.setItem(STEPS_TYPE.ONBOARDING, ONBOARDING_STEPS.CHOOSE_PATH);
          const message: Message = new Message(getName(MessageEnum.NavigationMessage))
          message.addData(
            getName(MessageEnum.NavigationTargetMessage),
            'CompanySuccess'
          );
          message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
          this.send(message);
        } else {
          //Check Error Response
          const errors = this.parseApiErrorResponse(responseJson);

          enqueueSnackbar(
            `${errors}`,
            { variant: 'error' }
          )
        }
        break;
    }
  }

  fetchRoleList(): boolean {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.roleMessageId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.roleListEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );


    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  handleCheckboxChange = (id: any) => {
    this.setState((prevState) => {
      const gtmChallengeValue = prevState.gtmChallengeValue.includes(id)
        ? prevState.gtmChallengeValue.filter((checkboxId) => checkboxId !== id)
        : [...prevState.gtmChallengeValue, id];

      return { gtmChallengeValue };
    });

    const updatedGtmChallengeValue = this.state.gtmChallengeValue.includes(id)
      ? this.state.gtmChallengeValue.filter((checkboxId) => checkboxId !== id)
      : [...this.state.gtmChallengeValue, id];

    return updatedGtmChallengeValue

  };

  handleToggleDisplay = () => {
    this.setState((prevState) => ({
      displayCount:
        prevState.displayCount === this.state.initialDisplayCount
          ? this.state.gtmChallenge.length
          : this.state.initialDisplayCount,
    }));
  };

  handleCompanyAPI = (responseJson: any) => {
    if (!responseJson.errors) {
      if (responseJson.data) {
        let newArr: any[] = []
        responseJson.data.forEach((detail: any) => {
          newArr.push(detail)
        })
        this.setState({ companySize: [...this.state.companySize, ...newArr] })
      }
    } else {
      //Check Error Response
      const errors = this.parseApiErrorResponse(responseJson);

      enqueueSnackbar(
        `${errors}`,
        { variant: 'error' }
      )
    }
  }

  handleLocationListAPI = (responseJson: any) => {
    if (!responseJson.errors) {
      if (responseJson.data) {
        this.setState({ locationList: jsonToArray(countryFile) })
      }
    } else {
      //Check Error Response
      const errors = this.parseApiErrorResponse(responseJson);

      enqueueSnackbar(
        `${errors}`,
        { variant: 'error' }
      )
    }
  }
  handleGtmChallengesListAPI = (responseJson: any) => {
    if (!responseJson.errors) {
      if (responseJson.data) {
        this.setState({ gtmChallenge: responseJson.data })
      }
    } else {
      //Check Error Response
      const errors = this.parseApiErrorResponse(responseJson);

      enqueueSnackbar(
        `${errors}`,
        { variant: 'error' }
      )
    }
  }

  getButtonTitle = () => {
    return !!this.targetScreen ? 'Go to Pebbles Ai' : 'Try Pebbles Ai'
  }

  handleAiButton = async () => {
    StorageProvider.setItem('target-screen', '')
    StorageProvider.setItem("isBusiness", "true");
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      this.targetScreen || 'ChooseYourPath'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    this.send(message);
  }

  handleIndustyAPI = (responseJson: any) => {
    if (!responseJson.errors) {
      if (responseJson.data) {
        this.setState({ industry: [...this.state.industry, ...responseJson.data] })
      }
    } else {
      //Check Error Response
      const errors = this.parseApiErrorResponse(responseJson);

      enqueueSnackbar(
        `${errors}`,
        { variant: 'error' }
      )
    }
  }

  handleCompanyHereAPI = (responseJson: any) => {
    if (!responseJson.errors) {
      if (responseJson.data) {
        let newArr: any[] = []
        responseJson.data.forEach((hereAbout: any) => {
          newArr.push(hereAbout)
        })
        this.setState({ hereAbout: [...this.state.hereAbout, ...newArr] })
      }
    } else {
      //Check Error Response
      const errors = this.parseApiErrorResponse(responseJson);

      enqueueSnackbar(
        `${errors}`,
        { variant: 'error' }
      )
    }
  }

  fetchCompanySize(): boolean {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchCompaniesId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.companySizeEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );


    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }


  fetchGtmChallenges(): boolean {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchGtmChallengeId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.gtmChallengesEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );


    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  fetchLocationList = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchLocationId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.countryEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );


    runEngine.sendMessage(requestMessage.id, requestMessage);

  }


  fetchIndustries(): boolean {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchIndustriesId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.industriesEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );


    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  fetchCompanyHereAbout(): boolean {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchCompanyHereAboutId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.companytHearAboutEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );


    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  async postAbout(role: string, industry: string, size: string, link: string, location: string) {
    const token = this.token
    this.setState({ isLoad: true })
    const formData = new FormData()
    const chosenRole = this.state.roleList.find(i => i.id == role);
    formData.append("profile_role", chosenRole.name)
    formData.append("role_id", chosenRole.id)
    formData.append("industry_id", industry)
    formData.append("gtm_team_size_id", size)
    formData.append("website", link)
    formData.append("country_name", location)

    const header = {
      token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPostAccountId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.signUpAboutEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true
  }

  async comapnyAboutPost(value: AboutValues) {
    const token = this.token
    this.setState({ isLoad: true })
    const formData = new FormData()
    formData.append("gtm_team_size_id", value.size)
    formData.append("hear_about_us_id", value.aboutUs)
    formData.append("gtm_challenges_ids[]", this.state.gtmChallengeValue as any);
    const header = {
      token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiCompanyAboutPost = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.companytellUsEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true
  }
}
