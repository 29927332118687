import { IBlock } from "../../../framework/main/IBlock";
import MessageEnum, {
  getName
} from "../../../framework/main/Messages/MessageEnum";
import { Message } from "../../../framework/main/Message";
import { BlockComponent } from "../../../framework/main/BlockComponent";

import { runEngine } from "../../../framework/main/RunEngine";

import { WithStyles } from "@material-ui/core";
import StorageProvider from "../../../framework/main/StorageProvider";
import { ONBOARDING_STEPS, STEPS_TYPE } from "../../../utils/enums";

export const configJSON = require("./config");

export interface Props extends WithStyles<any> {
  navigation: any;
  history: any;
}

interface S { }

interface SS {
  navigation: any;
}

export default class BeforeWeStartController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {};

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async receive(from: string, message: Message) {
    return Promise.resolve();
  }

  async componentDidMount() {
    super.componentDidMount();
  };

  aboutCompany = async () => {
    this.props.history.push('/upload-company-info');

    // window.localStorage.setItem(
    //   STEPS_TYPE.ONBOARDING,
    //   ONBOARDING_STEPS.ONBOARDING_STARTED
    // );
    // const message: Message = new Message(
    //   getName(MessageEnum.NavigationMessage)
    // );
    // message.addData(
    //   getName(MessageEnum.NavigationTargetMessage),
    //   "BulkUploading"
    // );
    // message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    // this.send(message);
  };

  ChooseYourPath = () => {
    this.props.history.push('/choose-your-path');
    
    // const message: Message = new Message(
    //   getName(MessageEnum.NavigationMessage)
    // );
    // message.addData(
    //   getName(MessageEnum.NavigationTargetMessage),
    //   "ChooseYourPath"
    // );
    // message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    // this.send(message);
  };
}
