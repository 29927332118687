import React, { useEffect, useRef, useState } from "react";
import { Backdrop, Box, Button, Checkbox, CircularProgress, InputAdornment, Link, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import TextField from "../../../components/main/CustomFields/TextField";
import { getProfile, createProfile } from "../../../redux/services/profile";
import { clearStorages, jsonToArray } from "../../../helpers/other";
import { confirmOtpCode, updateAccountDetails } from "../../../redux/services/authentication";
import StorageProvider from "../../../framework/main/StorageProvider";
import { enqueueSnackbar } from "notistack";
import Dropdown from "../../../components/main/CustomFields/Dropdown";
import { getIndustries, getJobRoles, getTeamSize } from "../../../redux/services/common";
import { COUNTRIES } from "../../../utils/countries";
import Autocomplete from "../../../components/main/CustomFields/Autocomplete";
import { ReactComponent as MarkerIcon } from '../../../assets/icons/marker_icon.svg';
import { removeProfile } from "../../../redux/features/profile";
import { useDispatch } from "react-redux";

import { useStyles } from "./styles";

const urlRegex = /((https?):\/\/)?(www\.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(\/[a-zA-Z0-9#-]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;

const AccountDetails = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [profile, setProfile] = useState<any>(null);
  const [jobRoles, setJobRoles] = useState<{ value: number, label: string }[]>([]);
  const [industries, setIndustries] = useState<{ value: number, label: string }[]>([]);
  const [teamSize, setTeamSize] = useState<{ value: number, label: string }[]>([]);

  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    handleGetProfile();
    handleGetJobRoles();
    handleGetIndustries();
    handleGetTeamSize();
  }, []);

  const handleGetProfile = async () => {
    setLoading(true);

    const { data, error } = await getProfile();

    if (data && !error) {
      setProfile(data);
    } else {
      await clearStorages();

      history.push('/');
    }

    setLoading(false);
  }

  const handleGetJobRoles = async () => {
    const { data, error } = await getJobRoles();

    if (data && Array.isArray(data) && !error) {
      setJobRoles(
        data.map(item => ({
          value: item.id,
          label: item.name
        }))
      );
    }
  }

  const handleGetIndustries = async () => {
    const { data, error } = await getIndustries();

    if (data && Array.isArray(data) && !error) {
      setIndustries(
        data.map(item => ({
          value: item.id,
          label: item.industryName
        }))
      );
    }
  }

  const handleGetTeamSize = async () => {
    const { data, error } = await getTeamSize();

    if (data && Array.isArray(data) && !error) {
      setTeamSize(
        data.map(item => ({
          value: item.id,
          label: item.size
        }))
      );
    }
  }

  const handleSaveDetails = async ({
    role,
    industry,
    size,
    website,
    location
  }) => {
    setLoading(true);

    const { error } = await createProfile({
      accountId: profile.account.uuid,
      companyName: profile.account.companyName,
      roleId: role,
      industryId: industry,
      gtmTeamSizeId: size,
      website: website,
      location: location
    });

    if (!error) {
      await getProfile(true);

      history.push('/choose-your-plan');
    } else {
      enqueueSnackbar(
        error?.data?.message || 'Something went wrong',
        { variant: 'error' }
      )
    }

    setLoading(false);
  }

  return (
    <>
      <Backdrop
        style={{ color: '#fff', zIndex: 9999 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box className={classes.layoutContainer}>
        <Box className={classes.viewBox}>
          <Typography className={classes.createAccount}>
            Tell us about yourself
          </Typography>
          <Typography className={classes.signingAs}>
            This lets us send tailored updates like new pebble launches.
          </Typography>
          <Formik
            initialValues={{
              role: undefined,
              industry: undefined,
              size: undefined,
              website: '',
              location: ''
            }}
            validationSchema={Yup.object().shape({
              role: Yup.string()
                .required("Please select your role."),
              industry: Yup.string()
                .required("Please select an industry."),
              size: Yup.string()
                .required("Please select a company size."),
              website: Yup.string()
                .matches(urlRegex, "This link is invalid.")
                .required("Please enter company website."),
              location: Yup.string()
                .required("Please select a country.")
            })}
            onSubmit={values => handleSaveDetails(values)}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              setFieldValue,
              submitForm
            }) => (
              <Form
                translate={undefined}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px',
                  marginTop: '32px'
                }}
              >
                <Dropdown
                  name={'role'}
                  value={jobRoles.find(item => item.value === values['role'])?.label}
                  options={jobRoles}
                  placeholder={'Select job role'}
                  label={'What is your role?'}
                  error={(touched['role'] && errors['role']) as string}
                  onChange={(e) => setFieldValue('role', e.target.value)}
                />
                <Dropdown
                  name={'industry'}
                  value={industries.find(item => item.value === values['industry'])?.label}
                  options={industries}
                  placeholder={'Select your industry'}
                  label={'What industry are you in?'}
                  error={(touched['industry'] && errors['industry']) as string}
                  onChange={(e) => setFieldValue('industry', e.target.value)}
                />
                <Dropdown
                  name={'size'}
                  value={teamSize.find(item => item.value === values['size'])?.label}
                  options={teamSize}
                  placeholder={'Select size'}
                  label={'What is the size of your GTM team?'}
                  error={(touched['size'] && errors['size']) as string}
                  onChange={(e) => setFieldValue('size', e.target.value)}
                />
                <TextField
                  name={'website'}
                  value={values['website']}
                  label={'What is your company website?'}
                  placeholder={'Insert link here'}
                  error={touched['website'] && errors['website']}
                  onChange={(e) => setFieldValue('website', e.target.value)}
                />
                <Autocomplete
                  name={'location'}
                  value={
                    Object.entries(COUNTRIES).map(([key, value]) => ({
                      value: key,
                      label: value,
                    })).find(item => item.label === values['location'])
                  }
                  label={'In what country is the main office situated?'}
                  options={
                    Object.entries(COUNTRIES).map(([key, value]) => ({
                      value: key,
                      label: value,
                    }))
                  }
                  placeholder={'Search location'}
                  error={touched['location'] && errors['location']}
                  inputAdornment={<MarkerIcon />}
                  onChange={(e, value) => setFieldValue('location', value ? value.label : '')}
                />
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  type="submit"
                  style={{
                    marginTop: '16px'
                  }}
                >
                  Continue
                </Button>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    </>
  );
};

export default AccountDetails;
