import React, { CSSProperties, useCallback, useEffect, useRef, useState } from "react";
import debounce from 'lodash/debounce';
import { Backdrop, Box, Button, CircularProgress, Container, IconButton, InputAdornment, Typography } from "@material-ui/core";
import { Edit, InsertDriveFile } from "@material-ui/icons";
import moment from "moment";
import { WebFile } from "../../blocks/bulkuploading/main/BulkUploadingCompanyInfoController.web";
import UploadCompanyDocument from "../../blocks/documentation/main/UploadCompanyDocument.web";
import { uploadIcon } from "../../blocks/documentation/main/assets";
import { searchIcon } from "../../blocks/email-account-registration/main/assets";
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete.svg';
import { ReactComponent as UploadIcon } from '../../assets/icons/upload_black.svg';
import CompanyIntelDeletePopup from "../../components/main/CompanyIntelDeletePopup";
import ConfirmationModal from "../../components/main/ConfirmationModal";
import UploadClosePopup from "../../components/main/UploadClosePopup";
import MessageEnum, { getName } from "../../framework/main/Messages/MessageEnum";
import { runEngine } from "../../framework/main/RunEngine";
import StorageProvider from "../../framework/main/StorageProvider";
import { convertFromBytes } from "../../helpers/file";
import { getCurrentSubscriptionStatus } from "../../helpers/subscription";
import { deleteCompanyIntelData, getCompanyIntelAttachments, uploadCompanyIntelInformation } from "../../redux/services/company-intel";
import { getCurrentSubscription } from "../../redux/services/subscriptions";
import { useHistory } from "react-router-dom";
import TextField from "../../components/main/CustomFields/TextField";
import { enqueueSnackbar } from "notistack";
import { getFileTypesData } from "../../redux/services/common";
import DocumentPreviewModal from './components/DocumentPreviewModal';

import { useStyles } from "./styles";

const CompanyIntel = (props) => {
  const [errors, setErrors] = useState('');
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [showUploadDocumentModal, setShowUploadDocumentModal] = useState(false);
  const [highlightInfo, setHighlightInfo] = useState(false);
  const [infoDialog, setInfoDialog] = useState(false);
  const [docFiles, setDocFiles] = useState<WebFile[]>([]);
  const [error, setError] = useState('');
  const [isAboutCompany, setIsAboutCompany] = useState(true);
  const [isOpenDeletePop, setIsOpenDeletePop] = useState({ active: false, id: null });
  const [companyDoc, setCompanyDoc] = useState({});
  const [targetDoc, setTargetDoc] = useState({});
  const [listID, setListID] = useState(0);
  const [serchFile, setSearchFile] = useState('');
  const [showMessage, setShowMessage] = useState('');
  const [isImports, setIsImports] = useState(false);
  const [isSubscriptionActive, setIsSubscriptionActive] = useState(false);
  const [showActionBlockedModal, setShowActionBlockedModal] = useState(false);
  const [token, setToken] = useState<string | null>(null);
  const [fileTypeCompany, setFileTypeCompany] = useState<any[]>([]);
  const [fileTypeTarget, setFileTypeTarget] = useState<any[]>([]);
  const [preview, setPreview] = useState<{ active: boolean, document: { uri: string, name: string, type: string } | null }>({
    active: false,
    document: null
  });

  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    getCompanyFileTypeLists();
    getTargetFileTypeLists();
  }, []);

  useEffect(() => {
    setLoading(true);

    Promise.allSettled([
      handleIsSubscriptionActive()
    ]).then(() => {
      fetchDocumentList(serchFile, isAboutCompany);
    })
  }, [isAboutCompany]);

  const fetchDocumentList = useCallback(async (search = '', isAboutCompany = true) => {
    setFetching(true);

    const { data, error } = await getCompanyIntelAttachments({
      fileName: search,
      informationType: isAboutCompany ? 'COMPANY' : 'AUDIENCE'
    });

    if (!error && data && Array.isArray(data)) {
      const today = moment().startOf('day');
      const yesterday = moment().subtract(1, 'days').startOf('day');
      const groupedData = Object.fromEntries(
        Object.entries(
          data.reduce((acc, item) => {
            const updatedAt = moment(item.updatedAt);
            let key;

            if (item.updatedAt) {
              if (updatedAt.isSame(today, 'd')) {
                key = 'Today';
              } else if (updatedAt.isSame(yesterday, 'd')) {
                key = 'Yesterday';
              } else {
                key = updatedAt.format('DD MMMM YYYY');
              }
            } else {
              key = 'Unknown';
            }

            if (!acc[key]) {
              acc[key] = [];
            }

            acc[key].push(item);
            return acc;
          }, {})
        ).reverse()
      );

      setCompanyDoc(groupedData);
      setTargetDoc(groupedData);
    }

    setFetching(false);
    setLoading(false);
  }, []);

  const handleIsSubscriptionActive = async () => {
    const { data } = await getCurrentSubscription();
    if (getCurrentSubscriptionStatus(data) !== 'EXPIRED') {
      setIsSubscriptionActive(true);
    }
  };

  const handleOnboarding = async () => {
    if (isSubscriptionActive) {
      await StorageProvider.setItem("isEdit", 'true');

      history.push('/onboarding');
    } else {
      setShowActionBlockedModal(true);
    }
  };

  const handleActiveTab1 = () => {
    setIsAboutCompany(true);
    setSearchFile('');
    setCompanyDoc({});
    setTargetDoc({});
    fetchDocumentList('', true);
  };

  const handleActiveTab2 = () => {
    setIsAboutCompany(false);
    setSearchFile('');
    setCompanyDoc({});
    setTargetDoc({});
    fetchDocumentList('', false);
  };

  const handelIsOpenUpload = async () => {
    if (isSubscriptionActive) {
      await StorageProvider.setItem("isEdit", 'true');

      setDocFiles([]);
      setShowUploadDocumentModal(!showUploadDocumentModal);
    } else {
      setShowActionBlockedModal(true);
    }
  };

  const debouncedSearch = useCallback(
    debounce((searchText: string, isAboutCompany: boolean) => {
      fetchDocumentList(searchText, isAboutCompany);
    }, 500),
    []
  );

  const handleSearchTextCompanyIntel = (searchText: string) => {
    setSearchFile(searchText);

    debouncedSearch(searchText, isAboutCompany);
  };

  const onChangeUploadFile = (files?: FileList, erroneous?: { file: File, message: string }[]) => {
    if (erroneous?.length) {
      setError(`${erroneous.length} file${erroneous.length > 1 ? 's' : ''} failed`);
    } else if (docFiles.length >= (isAboutCompany ? 3 : 10)) {
      setError(`You can upload ${isAboutCompany ? 3 : 10} documents max`);
    } else {
      setError('');
    }

    let tempFiles: WebFile[] = [];

    Array.from(files).slice(0, !docFiles.length ? (isAboutCompany ? 3 : 10) : Math.abs(docFiles.length - (isAboutCompany ? 3 : 10))).forEach((_file) => {
      const willAddfiles: any = _file;
      willAddfiles.information_type = 0;
      tempFiles.push(willAddfiles as unknown as WebFile);
    });

    setDocFiles([...docFiles, ...tempFiles]);

    if (docFiles.length >= (isAboutCompany ? 3 : 10)) {
      setError(`You can upload ${isAboutCompany ? 3 : 10} documents max`);
    }
  };

  const getCompanyFileTypeLists = async () => {
    const { data } = await getFileTypesData({ file_type: "About your company" });

    if (Array.isArray(data)) {
      const fileTypeCompany = data.map((type: any) => ({
        id: type.id,
        name: type.description,
      }));

      fileTypeCompany.unshift({
        id: 0,
        name: "Choose file type",
      });

      setFileTypeCompany(fileTypeCompany);
    }
  }

  const getTargetFileTypeLists = async () => {
    const { data } = await getFileTypesData({ file_type: "Target audience" });

    if (Array.isArray(data)) {
      const fileTypeTarget = data.map((type: any) => ({
        id: type.id,
        name: type.description,
      }));

      fileTypeTarget.unshift({
        id: 0,
        name: "Choose file type",
      });

      setFileTypeTarget(fileTypeTarget);
    }
  }

  const handleDeleteCompanyIntel = async (id) => {
    setLoading(true);

    setIsOpenDeletePop({ active: false, id: null });

    const { data } = await deleteCompanyIntelData(id);

    if (data && 'error' in data) {
      enqueueSnackbar(
        'Document deletion failed',
        { variant: 'error' }
      );
    } else {
      enqueueSnackbar(
        'Document deleted successfully',
        { variant: 'success' }
      );
    }

    await fetchDocumentList(serchFile, isAboutCompany);

    setLoading(false);
  }

  const handleChangeFileInfomation = (fileId: string, value: number) => {
    const index = docFiles.findIndex((file) => file.id === fileId);
    if (index < 0) {
      return;
    }
    const newWebFiles = [...docFiles];

    newWebFiles[index].information_type = value;

    setDocFiles(newWebFiles);
  };

  const uploadMultipleCompanyFile = () => {
    if (docFiles.length) {
      if (isAboutCompany && Object.values(companyDoc).flat().length >= 3) {
        enqueueSnackbar(
          `Only 3 "About your company" files can be attached to this account`,
          { variant: 'error' }
        )
      } else {
        setLoading(true);
  
        setShowUploadDocumentModal(false);
        // this.handleOpenUploadClosePop();
        // this.handleCloseDeletePop();
        // this.handleCloseUploadClosePop();
  
        const uploadFiles = docFiles;
  
        if (!uploadFiles.length) {
          const message = "Please attach file.";
  
          setError(message);
  
          setTimeout(() => {
            setError('');
          }, 2000);
  
          return;
        }
  
        const promises = uploadFiles.map(async file => {
          return await uploadCompanyIntelInformation({
            file,
            type: isAboutCompany
              ? fileTypeCompany.find(item => item.id === file.information_type).name
              : fileTypeTarget.find(item => item.id === file.information_type).name
          });
        });
  
        Promise.allSettled(promises).then(res => {
          const erroneous = res.filter((item: any) => 'error' in item.value);
          const successful = res.filter((item: any) => 'data' in item.value && !('error' in item.value));
  
          if (successful.length) {
            enqueueSnackbar(
              `${successful.length} company intel document(s) uploaded successfully`,
              { variant: 'success' }
            );
          }
  
          if (erroneous.length) {
            enqueueSnackbar(
              `${erroneous.length} company intel document(s) failed in the uploading process`,
              { variant: 'error' }
            );
          }
  
          fetchDocumentList(serchFile, isAboutCompany);
        });
  
        setLoading(false);
      }
    }
  };

  const getColor = (name: string) => {
    if (name.includes(".pdf")) {
      return "#E04E4E";
    }

    if (name.includes(".docx")) {
      return "#3166ED";
    }

    return "#00B84A";
  };

  return (
    <>
      <Backdrop
        style={{ color: '#fff', zIndex: 9999 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Container className={classes.mainContainer}>
        <Box className={classes.mainBox}>
          <Box className={classes.uploadContainer}>
            <Typography style={{ fontSize: '48px', fontWeight: '600', lineHeight: '58px' }}>
              Company Intel
            </Typography>
            <Box className={classes.tabContainer}>
              <Button
                className={isAboutCompany
                  ? classes.activeAction
                  : classes.activeButton
                }
                onClick={handleActiveTab1}
              >
                About your company
              </Button>
              <Button
                className={!isAboutCompany
                  ? classes.activeAction
                  : classes.activeButton
                }
                onClick={handleActiveTab2}
              >
                Target audience
              </Button>
            </Box>
          </Box>
          <Box className={classes.uploadContainer}>
            <Box style={{ width: '100%', display: 'flex', justifyContent: 'space-between', gap: '8px' }}>
              <Button
                className={classes.CompanyIntelButton}
                endIcon={<UploadIcon className={classes.iconBtn} />}
                // disabled={Object.values(companyDoc).flat().length >= 3}
                onClick={handelIsOpenUpload}
              >
                Upload new
              </Button>
              <Button
                className={classes.CompanyIntelButton}
                endIcon={<Edit className={classes.iconBtn} />}
                onClick={handleOnboarding}
              >
                Edit onboarding
              </Button>
            </Box>
            <TextField
              value={serchFile}
              type={'search'}
              placeholder={'Search documents'}
              showClear
              onChange={(event: any, value: any) => {
                if (isSubscriptionActive) {
                  handleSearchTextCompanyIntel(event.target.value)
                } else {
                  setShowActionBlockedModal(true)
                }
              }}
            />
          </Box>
        </Box>
        <Box className={classes.listMainContainer}>
          {!loading && fetching && (
            <Box
              style={{
                width: '100%',
                height: '50vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'absolute',
              }}
            >
              <CircularProgress color="inherit" />
            </Box>
          )}
          {!loading && !fetching && !Object.entries(isAboutCompany ? companyDoc : targetDoc).length && (
            <Box
              style={{
                width: '100%',
                height: '50vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'absolute',
              }}
            >
              <Typography
                style={{
                  fontSize: '16px',
                  fontWeight: '600',
                  lineHeight: '19px'
                }}
              >
                No data
                {/* {serchFile.length
                    ? 'No results'
                    : 'No data'
                  } */}
              </Typography>
            </Box>
          )}
          {Object.entries(isAboutCompany ? companyDoc : targetDoc).map(([key, items]: any, index: number) => (
            <Box key={index} style={{ display: 'flex', flexDirection: 'column', rowGap: '16px' }}>
              <Typography style={{ fontSize: '16px', fontWeight: '600', lineHeight: '19px' }}>
                {key}
              </Typography>
              {items.map((item: any, index: any) => {
                return (
                  <>
                    <Box 
                      key={index + item.filename} 
                      className={classes.listContainer}
                      // onClick={() => {
                      //   setPreview({ 
                      //     active: true, 
                      //     document: { 
                      //       uri: 'https://pdfobject.com/pdf/sample.pdf', 
                      //       name: item.filename, 
                      //       type: item.contentType, 
                      //     } 
                      //   })
                      // }}
                    >
                      <Box style={{ width: '50%', display: 'flex' }}>
                        <Box style={{ display: "flex", }}>
                          <InsertDriveFile
                            style={{
                              width: "40px",
                              marginRight: '4px',
                              height: "40px",
                              color: getColor(item.filename),
                            }}
                          />
                        </Box>
                        <Box style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                          <Typography noWrap style={{ maxWidth: '90%', fontSize: '16px', fontWeight: '600', lineHeight: '19px' }}>
                            {item.filename}
                          </Typography>
                          <Box style={{ display: 'flex', alignItems: 'center', columnGap: '8px', marginTop: '4px' }}>
                            <Box className={classes.spanText}>
                              {item.contentType.toUpperCase()} • {convertFromBytes(item.attachmentSize)}
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      <Box style={{ width: '40%', display: 'flex', justifyContent: 'flex-start' }}>
                        <Typography className={classes.fileTags}>{item.informationTypeDescription}</Typography>
                      </Box>
                      <IconButton
                        onClick={() => setIsOpenDeletePop({ active: true, id: item.id })}
                      >
                        <DeleteIcon />
                        {/* <img
                          src={deleteIcon}
                          alt="remove"
                          className={classes.iconBtn2}
                          onClick={() => setIsOpenDeletePop({ active: true, id: item.id })}
                        /> */}
                      </IconButton>
                    </Box>
                  </>
                )
              })}
            </Box>
          ))}
          {/* {visibleItems < targetDoc?.length &&
              <Button className={classes.viewButton} onClick={handleViewMoreTarget}>View More</Button>
            } */}
        </Box>
      </Container>

      <ConfirmationModal
        open={showActionBlockedModal}
        title={'To proceed, you have to be subscribed'}
        onClose={() => setShowActionBlockedModal(false)}
        reversedButtons
        confirmBtnText={'Subscribe later'}
        rejectBtnText={'Go to subscriptions'}
        onConfirm={() => setShowActionBlockedModal(false)}
        onReject={() => history.push('/subscriptions')}
      />

      <UploadCompanyDocument
        isOpen={showUploadDocumentModal}
        handleOpenUploadClosePop={() => {
          setShowUploadDocumentModal(false);
          setDocFiles([]);
          setError('');
        }}
        uploadMultipleCompanyFile={uploadMultipleCompanyFile}
        isAboutCompany={isAboutCompany}
        toggleInfoDialog={() => setInfoDialog(!infoDialog)}
        onChangeUploadFile={onChangeUploadFile}
        docFiles={docFiles}
        fileTypeTarget={fileTypeTarget}
        fileTypeCompany={fileTypeCompany}
        isImports={isImports}
        handleChangeFileInfomation={handleChangeFileInfomation}
        getColor={getColor}
        removeFileWeb={(id) => setDocFiles(docFiles.filter((item) => item.id !== id))}
        error={error}
        infoDialog={infoDialog}
      />

      <ConfirmationModal
        open={isOpenDeletePop.active}
        title={<>Are you sure you want to <br /> delete this company intel document?</>}
        onClose={() => setIsOpenDeletePop({ active: false, id: null })}
        confirmBtnText="Delete"
        rejectBtnText="Cancel"
        onConfirm={() => handleDeleteCompanyIntel(isOpenDeletePop.id)}
        onReject={() => setIsOpenDeletePop({ active: false, id: null })}
      />

      <DocumentPreviewModal
        open={preview.active}
        document={preview.document}
        onClose={() => {
          setPreview({
            active: false,
            document: null
          })
        }}
      />

      {/* <UploadClosePopup
        open={isUploadClosePop}
        handleCloseUploadClosePop={handleCloseUploadClosePop}
        handleDeleteUploadPopup={handleDeleteUploadPopup}
      /> */}
    </>
  );
};

export default CompanyIntel;
