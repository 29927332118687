import { api, apiCall } from "../api";
import { ApiResponse } from "../types";

const myLibraryAPI = api.injectEndpoints({
  endpoints: (builder) => ({
    getLibraryCampaigns: builder.query<any, any>({
      query: () => ({
        url: `/v1/library/campaigns`,
        method: "GET"
      }),
    }),
    createLibraryCampaign: builder.mutation<any, any>({
      query: (body) => ({
        url: `/v1/library/campaigns`,
        method: "POST",
        body
      })
    }),
    duplicateLibraryCampaign: builder.mutation<any, any>({
      query: (campaignId) => ({
        url: `/v1/library/campaigns/${campaignId}/duplicate`,
        method: "PUT"
      })
    }),
    deleteLibraryCampaign: builder.mutation<any, any>({
      query: (campaignId) => ({
        url: `/v1/library/campaigns/${campaignId}`,
        method: "DELETE"
      })
    }),
    duplicateLibraryEmail: builder.mutation<any, any>({
      query: ({ campaignId, emailId }) => ({
        url: `/v1/library/campaigns/${campaignId}/email/${emailId}/duplicate`,
        method: "PUT",
      })
    }),
    deleteLibraryEmail: builder.mutation<any, any>({
      query: ({ campaignId, emailId }) => ({
        url: `/v1/library/campaigns/${campaignId}/email/${emailId}`,
        method: "DELETE"
      })
    })
  }),
  overrideExisting: true
});

// Create a function to call the API without using the hook
export const getLibraryCampaigns = async (): Promise<any> => {
  return apiCall(myLibraryAPI.endpoints.getLibraryCampaigns);
};

export const createLibraryCampaign = async (body): Promise<any> => {
  return apiCall(myLibraryAPI.endpoints.createLibraryCampaign, body);
};

export const duplicateLibraryCampaign = async (campaignId): Promise<any> => {
  return apiCall(myLibraryAPI.endpoints.duplicateLibraryCampaign, campaignId);
};

export const deleteLibraryCampaign = async (campaignId): Promise<any> => {
  return apiCall(myLibraryAPI.endpoints.deleteLibraryCampaign, campaignId);
};

export const duplicateLibraryEmail = async ({ campaignId, emailId }): Promise<any> => {
  return apiCall(myLibraryAPI.endpoints.duplicateLibraryEmail, { campaignId, emailId });
};

export const deleteLibraryEmail = async ({ campaignId, emailId }): Promise<any> => {
  return apiCall(myLibraryAPI.endpoints.deleteLibraryEmail, { campaignId, emailId });
};
