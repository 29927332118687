import { api, apiCall } from "../api";
import { ApiResponse } from "../types";

const onboardingAPI = api.injectEndpoints({
  endpoints: (builder) => ({
    getOnboardingData: builder.query<any, void>({
      query: () => ({
        url: "/v1/onboarding/company_details",
        method: "GET"
      }),
    }),
    saveOnboardingData: builder.mutation<any, any>({
      query: (body) => ({
        url: "/v1/onboarding/company_details",
        method: "POST",
        body
      }),
      // transformResponse: (response: { data: any }) => response.data.attributes
    }),
    uploadOnboardingInformation: builder.mutation<any, any>({
      query: ({ file, type }) => {
        const formData = new FormData();
        formData.append("fileAttachments", file);

        return {
          url: `/v1/onboarding/attachments?fileType=${type}`,
          method: "POST",
          body: formData
        };
      }
    }),
    triggerAutofillOnboarding: builder.mutation<any, any>({
      query: (body) => ({
        url: "/v1/onboarding/intels",
        method: "POST",
        body
      })
    }),
    getOnboardingCompleteness: builder.query<any, void>({
      query: () => ({
        url: "/v1/onboarding/company_details/completeness",
        method: "GET"
      }),
    })
  }),
  overrideExisting: true
});

// Create a function to call the API without using the hook
export const getOnboardingData = async (): Promise<any> => {
  return apiCall(onboardingAPI.endpoints.getOnboardingData);
};

export const saveOnboardingData = async (body): Promise<any> => {
  return apiCall(onboardingAPI.endpoints.saveOnboardingData, body);
};

export const uploadOnboardingInformation = async (body): Promise<any> => {
  return apiCall(onboardingAPI.endpoints.uploadOnboardingInformation, body);
};

export const triggerAutofillOnboarding = async (body): Promise<any> => {
  return apiCall(onboardingAPI.endpoints.triggerAutofillOnboarding, body);
};

export const getOnboardingCompleteness = async (): Promise<any> => {
  return apiCall(onboardingAPI.endpoints.getOnboardingCompleteness);
};