import React, { useEffect, useState } from 'react';
import { Backdrop, Box, Button, CircularProgress, Typography } from '@material-ui/core';
import { ReactComponent as ThreeReferralsIcon } from '../../assets/icons/3_referrals.svg';
import { ReactComponent as MassReferralIcon } from '../../assets/icons/mass_referal.svg';
import { ReactComponent as LinkIcon } from '../../assets/icons/link.svg';
import ReferralsGiftRobotIcon from '../../assets/images/referrals_gift_robot.png';
import { enqueueSnackbar } from 'notistack';
import { getReferralCode, getReferralsAmount } from '../../redux/services/authentication';
import { useSelector } from 'react-redux';

import { useStyles } from './styles';

const ShareAndEarn = () => {
  const [options, setOptions] = useState<any>([
    {
      icon: <ThreeReferralsIcon />,
      color: '#3A84C9',
      titleColor: '#3A84C9',
      title: '3 friends',
      description: <>
        Successfully refer <b>3 friends</b>, who sign up beyond the 14-day trial, to receive <b>1 months</b> of GTM Professional <b>free</b>.
      </>,
      amount: {
        min: 0,
        required: 3,
        current: 0,
      },
    },
    {
      icon: <MassReferralIcon />,
      color: '#CAB354',
      titleColor: '#000000',
      title: 'Mass Referal',
      description: <>
        Successfully refer <b>10 friends</b>, who sign up beyond the 14-day trial, to receive <b>6 months</b> of GTM Professional <b>free</b>.
      </>,
      amount: {
        min: 3,
        required: 10,
        current: 0,
      },
    },
  ]);
  const [loading, setLoading] = useState<boolean>(false);

  const classes = useStyles();

  useEffect(() => {
    handleGetReferralsAmount();
  }, []);

  const handleGetReferralsAmount = async () => {
    const { data, error } = await getReferralsAmount();

    if (data && !error) {
      setOptions(
        options.map(item => ({
          ...item,
          amount: {
            ...item.amount,
            current: data.amount >= item.amount.required ? item.amount.required : data.amount
          }
        }))
      )
    }
  }

  const handleCopyLink = async () => {
    // setLoading(true);

    const { data, error } = await getReferralCode();

    if (data && !error) {
      navigator.clipboard.writeText(`${window.location.origin}/signup?ref=${data.code}`)
        .then(() => {
          enqueueSnackbar(
            'Referral link copied to clipborad',
            { variant: 'info' }
          )
        })
        .catch(err => {
          enqueueSnackbar(
            'Failed to copy',
            { variant: 'error' }
          )
        });
    } else {
      enqueueSnackbar(
        'Server error occurs',
        { variant: 'error' }
      )
    }

    // setLoading(false);
  }

  return (
    <>
      <Backdrop
        style={{ color: '#fff', zIndex: 9999 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box className={classes.container}>
        <Typography className={classes.title}>
          Share & Earn
        </Typography>
        <Box className={classes.referralBoxContainer}>
          {options.map((item, index) => (
            <Box
              key={index}
              className={classes.referralBox}
              style={{
                borderColor: item.color
              }}
            >
              <Box className={classes.referralHeader}>
                {item.icon}
                <Typography className={classes.referralProgress}>
                  <Typography
                    className={classes.referralCount}
                    style={{
                      color: item.color
                    }}
                  >
                    {item.amount.current}&nbsp;
                  </Typography>
                  / {item.amount.required} referrals
                </Typography>
              </Box>
              <Box className={classes.referralDetails}>
                <Typography
                  className={classes.referralFriends}
                  style={{
                    color: item.titleColor
                  }}
                >
                  {item.title}
                </Typography>
                <Typography className={classes.referralText}>
                  {item.description}
                </Typography>
              </Box>
              <Button
                variant='outlined'
                color='secondary'
                startIcon={<LinkIcon style={{ color: item.color }} />}
                disabled={item.amount.current < item.amount.min || item.amount.current >= item.amount.required}
                onClick={() => handleCopyLink()}
              >
                Get a referral link
              </Button>
            </Box>
          ))}
        </Box>

        <Box className={classes.additionalOffersContainer}>
          <Box className={classes.inviteFriendsBox}>
            <Box className={classes.inviteFriendsContent}>
              <Typography className={classes.inviteTitle}>
                INVITE FRIENDS & EARN REWARDS
              </Typography>
              <Typography className={classes.inviteText}>
                Invite 10 friends to join the platform and get a big present.
              </Typography>
            </Box>
            <img height={'200px'} src={ReferralsGiftRobotIcon} alt='Gift' />
          </Box>

          <Box className={classes.otherOffersBox}>
            <Typography className={classes.otherOffersText}>
              OTHER OFFERS COMING SOON
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ShareAndEarn;
